import { Alert, Button, Checkbox, CurrencyInput, DatePicker, Grid, Input, Layout, Loader, Modal, NumericInput, PhoneInput, Switch, Table, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { COMPANY_OPTIONS } from "../../../constants";
import { CreateAccountValidationSchema, CreateBidValidationSchema } from "@next-pontos/validations";
import { useAxios, useToggle } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import {useMemo, useEffect, Fragment} from 'react'
import { formatCPF, formatMoney, nl2br } from "../../../utils";
import * as Yup from 'yup'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, InputLabel, useTheme } from "@mui/material";
import { PriceSetting } from "../../programs/PriceSettingsInput";
import { Add, PlusOne } from "@mui/icons-material";
import { Tooltip as MuiTooltip } from "@mui/material";
import moment from "moment";

export function NewBid () { 
    const [bid, createBid] = useAxios({
        url: '/bid',
        method: 'POST'
    }, {
        manual: true
    })

    const [account, createAccount] = useAxios({
        url: '/account',
        method: 'POST'
    }, {manual: true})

    const [dashboard] = useAxios(`/user/dashboard`)
    const [programs] = useAxios(`/program`)
    const [accounts] = useAxios(`/account`)

    const [createAccountModalVisibility, createAccountModalVisibilityControls] = useToggle(false)
    
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            company: '',
            miles: '',
            price: '',
            pax: '',
            account_id: '',
            accepts_custom_payment: false
        },
        onSubmit: async values => {
            await createBid({ data: values })
            navigate('/minhas-ofertas')
        },
        validateOnMount: true,
        // @ts-ignore
        validationSchema: CreateBidValidationSchema.concat(Yup.object({
            miles: Yup.number().when('company', ([company], schema) => {
                const min_amount = (programs.data || []).find((program: { company: string }) => program.company === company)?.min_amount || 0
                
                return schema.min(min_amount, `O mínimo para esta companhia é ${Number(min_amount).toLocaleString('pt-BR')} milhas`).required('Este campo é obrigatório').test({
                    test: value => {
                        if (Number(value) <= 0) {
                            return false
                        }
            
                        return true
                    },
                    message: 'Deve ser maior que zero'
                })
            })
        }))
    })

    const createAccountFormik = useFormik({
        initialValues: {
            company: '',
            holder_name: '',
            holder_email: '',
            holder_cpf: '',
            holder_phone_number: '',
            login: '',
            password: '',
            secondary_password: '',
            is_membership_active: false,
            holder_birthday: '',
            membership_number: '',
        },
        onSubmit: async values => {
            const createdAccount = await createAccount({
                data: values
            })

            formik.setFieldValue('account_id', createdAccount.data.id)
            createAccountModalVisibilityControls.setFalse()
        },
        validateOnMount: true,
        validationSchema: CreateAccountValidationSchema
    })

    const paxOptions = useMemo(() => {
        return COMPANY_OPTIONS.find(item => item.value === formik.values.company)?.pax_options || []
    }, [formik.values.company])

    useEffect(() => {
        if (paxOptions.length === 1) {
            formik.setFieldValue('pax', paxOptions[0].value)
        } else {
            formik.setFieldValue('pax', '')
        }

        createAccountFormik.setFieldValue('company', formik.values.company)
    }, [formik.values.company, paxOptions])

    const lastPricePerK = useMemo(() => {
        const recent = dashboard?.data?.last_seven_days_k_price?.[dashboard?.data?.last_seven_days_k_price?.length - 1]

        return recent?.[formik.values.company]
    }, [dashboard?.data?.last_seven_days_k_price, formik.values.company])

    const theme = useTheme()
    
    const programPriceSettings = useMemo(() => {
        return programs?.data?.find((item: {company: string, program_price_settings: PriceSetting[]}) => item.company === formik.values.company)?.program_price_settings.filter((item: {direction: 'BUY'}) => item.direction === 'BUY') || []
    }, [formik.values.company, programs.data])

    const program = useMemo(() => {
        return (programs?.data || []).find((program: {company: string, active: boolean}) => {
            return program.company === formik.values.company
        })
    }, [formik.values.company, programs.data])

    return (
        <Fragment>
            <Layout>
                <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} alignItems="center" padding={3} spacing={2}>
                    {bid.loading || dashboard.loading || programs.loading || accounts.loading ? (
                        <div style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Loader />
                        </div>
                    ) : (
                        <Fragment>
                            <Grid item xs={12} sm={4}>
                                <Paper elevation={1}>
                                    <form onSubmit={formik.handleSubmit}>
                                    <Grid container padding={2} spacing={2} justifyContent="center">
                                        <Grid item xs={12} marginBottom={2}>
                                            <Typography textAlign={"center"} variant="h5">Nova Oferta</Typography>
                                        </Grid>
                                        {formik.values.company === 'GOL' && (<Grid item xs={12}>
                                            <Alert icon={false} color="success">
                                                Em ofertas <strong>Smiles</strong> utilizamos preferencialmente nossos cartões <strong>Smiles Visa Infinite</strong>, que geram milesback após a data do voo.
                                            </Alert>
                                        </Grid>)}
                                        {programPriceSettings.length > 0 && (<Grid item xs={12}>
                                            <Alert icon={false} color="info">
                                                <strong>Valores Médios de Milheiro (cada mil milhas):</strong>
                                                {
                                                    programPriceSettings.map((item: PriceSetting) => {
                                                        return (
                                                            <Fragment>
                                                                <br />
                                                                {
                                                                    item.to 
                                                                        ? `De ${Number(item.from).toLocaleString('pt-BR')} Até ${Number(item.to).toLocaleString('pt-BR')}: ${formatMoney(item.price)}`
                                                                        : `A partir de ${Number(item.from).toLocaleString('pt-BR')}: ${formatMoney(item.price)}`
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Alert>
                                        </Grid>)}
                                        <Grid item xs={12}> 
                                            <Input 
                                                label="Companhia"
                                                name="company"
                                                select
                                                options={COMPANY_OPTIONS.filter(({value}) => {
                                                    return (programs.data || []).find((program: {company: string, active: boolean}) => {
                                                        return program.company === value && program.active
                                                    })
                                                })}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.company}
                                                helperText={formik.touched.company && formik.errors.company}
                                                error={!!formik.touched.company && !!formik.errors.company}
                                            />
                                        </Grid>
                                        <Grid item xs={12}> 
                                            <Grid container>
                                                <Grid item xs={2} display={"flex"}>
                                                    <Button onClick={createAccountModalVisibilityControls.setTrue} disabled={!formik.values.company}><Add /></Button>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Input 
                                                        label="Conta"
                                                        name="account_id"
                                                        select
                                                        options={accounts.data.filter((item: {company: string}) => item.company === formik.values.company).map((item: {company: string, holder_name: string, id: string}) => ({
                                                            label: `[${COMPANY_OPTIONS.find(c => c.value === item.company)?.label}] ${item.holder_name}`,
                                                            value: item.id
                                                        }))}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.account_id}
                                                        helperText={formik.touched.account_id && formik.errors.account_id}
                                                        error={!!formik.touched.account_id && !!formik.errors.account_id}
                                                        disabled={!formik.values.company}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}> 
                                            <NumericInput 
                                                label="Quantidade de Milhas"
                                                name="miles"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.miles}
                                                helperText={formik.touched.miles && formik.errors.miles}
                                                error={!!formik.touched.miles && !!formik.errors.miles}
                                            />
                                        </Grid>
                                        <Grid item xs={12}> 
                                            <CurrencyInput 
                                                label="Valor do Milheiro (R$)"
                                                name="price"
                                                onChangeValue={(_, originalValue) => {
                                                    formik.setFieldValue('price', originalValue)
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.price}
                                                helperText={(formik.touched.price && formik.errors.price) || (!programPriceSettings && lastPricePerK > 0 ? `Valor sugerido ${formatMoney(lastPricePerK)}`: '')}
                                                error={!!formik.touched.price && !!formik.errors.price}
                                            />
                                        </Grid>
                                        <Grid item xs={12}> 
                                            <Input 
                                                label="Quantidade de CPFs"
                                                name="pax"
                                                select
                                                options={paxOptions}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.pax}
                                                helperText={(formik.touched.pax && formik.errors.pax) || (paxOptions.length <= 1 && 'Esta companhia não possui limitação de CPFs')}
                                                error={!!formik.touched.pax && !!formik.errors.pax}
                                                disabled={paxOptions.length <= 1}
                                            />
                                        </Grid>
                                        {program?.accept_custom_payment_days && (
                                            <Grid item xs={12}> 
                                                <FormControlLabel 
                                                    control={<Checkbox 
                                                        value={formik.values.accepts_custom_payment}
                                                        onChange={event => {
                                                            formik.setFieldValue('accepts_custom_payment', !formik.values.accepts_custom_payment, true)
                                                        }}
                                                    />} 
                                                    label="Aceita Pagamento à Prazo" 
                                                />
                                            </Grid>
                                        )}
                                        {program?.custom_bid_disclaimer && (
                                            <Grid item xs={12}>
                                                <Alert icon={false} color="info">
                                                    <span 
                                                        dangerouslySetInnerHTML={{
                                                            __html: nl2br(program.custom_bid_disclaimer)
                                                        }}
                                                    />
                                                </Alert>
                                            </Grid>
                                        )}
                                        <Grid item marginTop={2}>
                                            <Button disabled={!formik.isValid} type="submit">Criar Oferta</Button>
                                        </Grid>
                                    </Grid>
                                    </form>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} height={400}>
                                <Paper elevation={1} sx={{
                                    height: '100%',
                                    padding: 2
                                }}>
                                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                        Valores Médios de Mercado
                                    </Typography>
                                    <div style={{
                                        height: '100%'
                                    }}>
                                    <ResponsiveContainer
                                        height={'90%'}
                                    >
                                        {!dashboard.loading ? (<LineChart
                                            data={(dashboard.data?.last_seven_days_k_price || [])}
                                            margin={{
                                                top: 16,
                                                right: 16,
                                                left: 16,
                                            }}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="day"
                                            includeHidden
                                            stroke={theme.palette.text.secondary}
                                        >
                                            
                                        </XAxis>
                                        <YAxis
                                            stroke={theme.palette.text.secondary}
                                            style={theme.typography.body2}
                                            tickFormatter={tick => {
                                                return formatMoney(tick)
                                            }}
                                            tick
                                        >
                                        </YAxis>
                                        {
                                            Object.keys((dashboard.data?.last_seven_days_k_price || [])[0] || {})?.map(item => {
                                                if (item !== 'day' && (formik.values.company ? formik.values.company === item : true)) {
                                                    return (
                                                        <Line
                                                            isAnimationActive={false}
                                                            type="monotone"
                                                            dataKey={item}
                                                            stroke={COMPANY_OPTIONS.find(company => company.value === item)?.color}
                                                        />
                                                    )
                                                }

                                                return null
                                            })
                                        }
                                        
                                        <Tooltip
                                            formatter={(value: number, name) => {
                                                return [formatMoney(value), COMPANY_OPTIONS.find(item => item.value === name)?.label]
                                            }}
                                            itemStyle={{
                                                color: theme.palette.primary.main
                                            }}
                                            labelStyle={{
                                                fontWeight: 'bold'
                                            }}
                                            wrapperStyle={{
                                                zIndex: 10
                                            }}
                                        />
                                        <Legend
                                            formatter={value => {
                                                return COMPANY_OPTIONS.find(item => item.value === value)?.label
                                            }}
                                            fontWeight="bold"
                                        /> 
                                        </LineChart>) : <div/>}
                                    </ResponsiveContainer>
                                    </div>
                                </Paper>
                            </Grid>
                    </Fragment>)}
                </Grid>
            </Layout>
            <Modal
                open={createAccountModalVisibility}
                onClose={createAccountModalVisibilityControls.setFalse}
                title="Criar Conta"
                onConfirm={account.loading ? undefined : createAccountFormik.handleSubmit}
                confirmLabel="Salvar"
                bodyCustomCss={{
                    minWidth: 400
                }}
            >
                <Grid container padding={2} spacing={2} justifyContent="center">
                    {account.loading ? (
                        <Grid item xs={12} textAlign={"center"}>
                            <Loader />
                        </Grid>
                    ) : (<Fragment>
                        <Grid item xs={12} sm={6}> 
                            <MuiTooltip title="Você apenas pode adicionar contas para suas ofertas aceitas." placement="top">
                                <Input 
                                    label="Companhia"
                                    name="company"
                                    select
                                    options={COMPANY_OPTIONS}
                                    onChange={createAccountFormik.handleChange}
                                    onBlur={createAccountFormik.handleBlur}
                                    value={createAccountFormik.values.company}
                                    helperText={createAccountFormik.touched.company && createAccountFormik.errors.company}
                                    error={!!createAccountFormik.touched.company && !!createAccountFormik.errors.company}
                                />
                            </MuiTooltip>
                        </Grid>
                        <Grid item xs={12} sm={6} display="flex" alignItems="center" justifyContent="center"> 
                            <Switch
                                label="Possui clube ativo?"
                                checked={createAccountFormik.values.is_membership_active}
                                onChange={() => {
                                    createAccountFormik.setFieldValue('is_membership_active', !createAccountFormik.values.is_membership_active, false)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Input 
                                label="Nome do Titular"
                                name="holder_name"
                                onChange={createAccountFormik.handleChange}
                                onBlur={createAccountFormik.handleBlur}
                                value={createAccountFormik.values.holder_name}
                                helperText={createAccountFormik.touched.holder_name && createAccountFormik.errors.holder_name}
                                error={!!createAccountFormik.touched.holder_name && !!createAccountFormik.errors.holder_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Input 
                                label="E-mail do Titular"
                                name="holder_email"
                                onChange={createAccountFormik.handleChange}
                                onBlur={createAccountFormik.handleBlur}
                                value={createAccountFormik.values.holder_email}
                                helperText={createAccountFormik.touched.holder_email && createAccountFormik.errors.holder_email}
                                error={!!createAccountFormik.touched.holder_email && !!createAccountFormik.errors.holder_email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label="Data de Nascimento do Titular"
                                value={createAccountFormik.values.holder_birthday ? moment(createAccountFormik.values.holder_birthday) : null}
                                onChange={(value) => {
                                    createAccountFormik.setFieldValue('holder_birthday', value?.toDate(), true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <MuiTooltip
                                title="Preencha este campo com o número de fidelidade da companhia. Caso não haja preencha com o CPF."
                            >
                                <Input 
                                    label="Número de Fidelidade"
                                    name="membership_number"
                                    onChange={createAccountFormik.handleChange}
                                    onBlur={createAccountFormik.handleBlur}
                                    value={createAccountFormik.values.membership_number}
                                    helperText={createAccountFormik.touched.membership_number && createAccountFormik.errors.membership_number}
                                    error={!!createAccountFormik.touched.membership_number && !!createAccountFormik.errors.membership_number}
                                />
                            </MuiTooltip>
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Input 
                                label="CPF do Titular"
                                name="holder_cpf"
                                onChange={({target}) => {
                                    createAccountFormik.setFieldValue('holder_cpf', formatCPF(target.value))
                                }}
                                onBlur={createAccountFormik.handleBlur}
                                value={createAccountFormik.values.holder_cpf}
                                helperText={createAccountFormik.touched.holder_cpf && createAccountFormik.errors.holder_cpf}
                                error={!!createAccountFormik.touched.holder_cpf && !!createAccountFormik.errors.holder_cpf}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <PhoneInput
                                label="Telefone do Titular"
                                inputProps={{
                                    name: 'holder_phone_number'
                                }}
                                onChange={value => {
                                    createAccountFormik.setFieldValue('holder_phone_number', value, true)
                                }}
                                value={createAccountFormik.values.holder_phone_number}
                                // helperText={formik.touched.phone_number && formik.errors.phone_number}
                                onBlur={createAccountFormik.handleBlur}
                                error={!!createAccountFormik.touched.holder_phone_number && !!createAccountFormik.errors.holder_phone_number}
                            />
                        </Grid>
                        <Grid item xs={12}> 
                            <Input 
                                label="Login"
                                name="login"
                                onChange={createAccountFormik.handleChange}
                                onBlur={createAccountFormik.handleBlur}
                                value={createAccountFormik.values.login}
                                helperText={createAccountFormik.touched.login && createAccountFormik.errors.login}
                                error={!!createAccountFormik.touched.login && !!createAccountFormik.errors.login}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <Input 
                                label="Senha"
                                name="password"
                                type="password"
                                onChange={createAccountFormik.handleChange}
                                onBlur={createAccountFormik.handleBlur}
                                value={createAccountFormik.values.password}
                                helperText={createAccountFormik.touched.password && createAccountFormik.errors.password}
                                error={!!createAccountFormik.touched.password && !!createAccountFormik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}> 
                            <MuiTooltip title="Alguns programas de fidelidade possuem uma senha secundária. Caso não possua, não é necessário preencher este campo.">
                            <Input 
                                label="Contrassenha"
                                name="secondary_password"
                                type="password"
                                onChange={createAccountFormik.handleChange}
                                onBlur={createAccountFormik.handleBlur}
                                value={createAccountFormik.values.secondary_password}
                                helperText={createAccountFormik.touched.secondary_password && createAccountFormik.errors.secondary_password}
                                error={!!createAccountFormik.touched.secondary_password && !!createAccountFormik.errors.secondary_password}
                            />
                            </MuiTooltip>
                        </Grid>
                    </Fragment>)}
                </Grid>
            </Modal>
        </Fragment>
    )
}