import { Autocomplete, Button, ButtonGroup, CreditCardModal, CurrencyInput, DatePicker, Grid, Input, Layout, NumericInput, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { CreateOrderValidationSchema } from "@next-pontos/validations";
import { useAPI, useToggle } from "../../../hooks";
import {useEffect, useMemo, useCallback, useState, Fragment, useContext} from 'react'
import useSWR from 'swr'
import { Link, Tooltip } from "@mui/material";
import { formatCPF, formatDate, formatMoney } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../API";
import moment from "moment";
import { AIRLINES_OPTIONS, COMPANY_OPTIONS } from "../../../constants";
import currency from "currency.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import AuthContext from "../../../context/auth";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { APP_NAME } from "@next-pontos/settings";

export function NewOrder () { 
    const [creditCardModalVisibility, creditCardModalVisibilityControls] = useToggle(false)
    const navigate = useNavigate()
    const { me } = useContext(AuthContext)
    const params = useParams()
    const bid = useSWR(`/bid/${params.id}`, (route) => params.id ? API.get(route).then(result => result.data): null)
    const cards = useSWR(`/card`, (route) => API.get(route).then(result => result.data))

    const [passwordData, setPasswordData] = useState<null | { password: string, secondary_password: string}>(null)

    const bidApi = useAPI()
    const passwordApi = useAPI()
    const orderApi = useAPI()
    
    const formik = useFormik({
        initialValues: {
            flight_date: '',
            airline: '',
            reservation_code: '',
            amount: '',
            fees: '',
            additional_cost: '',
            card_id: '',
            sell_price: '',
            pax: '',
            author_name: me?.name || '',
            pax_name: '',
            origin_airport_code: ''
        },
        onSubmit: async (values) => {
            await orderApi.post(`/bid/${bid?.data?.id}/order`, values)
            
            window.location.replace(`/ofertas`)
        },
        validateOnMount: true,
        validationSchema: CreateOrderValidationSchema
    })

    useEffect(() => {
        if (me?.name) {
            formik.setFieldValue('author_name', me.name)
        }
    }, [me?.name])
    
    const CARDS_OPTIONS = useMemo(() => {
        return (cards?.data || []).map((card: { id: number, last_four_digits: string, brand: string, alias: string }) => ({
            value: card.id,
            label: `**** ${card.last_four_digits} (${card.alias})`
        }))
    }, [cards])

    const totalCost = useMemo(() => {
        const amount_k = currency(formik.values.amount || 0, {precision: 3}).divide(1000).value
        const price_per_k = bid?.data?.price_per_k || 0
        const fees = formik.values.fees || 0
        const additional_cost = formik.values.additional_cost || 0

        const miles_cost = currency(amount_k, {precision: 3}).multiply(price_per_k)
        const final_cost = miles_cost.add(fees).add(additional_cost)

        return final_cost.value
    }, [formik, bid])

    const airlineOptions = useMemo(() => {
        return AIRLINES_OPTIONS.map(item => ({
            value: item.label,
            label: item.label
        }))
    }, [])
    
    // useEffect(() => {
    //     if (params.id) {
    //         bidApi.post(`/bid/${params.id}/start-order`)
    //     }
    // }, [params])

    const handleRevealPassword = useCallback(async () => {
        const passwordResponse = await passwordApi.get(`/account/${bid?.data?.account_id}/password`)
        setPasswordData(passwordResponse.data)
    }, [passwordApi, bid?.data?.account_id])

    const paxOptions = useMemo(() => {
        const maxPax = bid?.data?.pax || 9
        
        const maxPaxOptions = (new Array(maxPax).fill(0)).map((_, index) => {
            return {
                label: (index + 1).toString(),
                value: (index + 1).toString()
            }
        })

        if (!bid?.data?.pax) {
            return maxPaxOptions
        } else {
            const usedPax = bid?.data?.orders?.filter((item: {confirmation: string}) => item.confirmation === 'PENDING' || item.confirmation === 'CONFIRMED')?.map((item: {pax: number}) => item.pax)?.reduce((prev: number, curr: number) => prev + curr, 0)
            
            return maxPaxOptions.filter((_, index) => index < (maxPaxOptions.length - usedPax))
        }

    }, [bid?.data?.pax, bid?.data?.orders])

    const milesAvailable = useMemo(() => {
        const usedMiles = bid?.data?.orders?.filter((item: {confirmation: string}) => item.confirmation === 'PENDING' || item.confirmation === 'CONFIRMED')?.map((item: {miles: number}) => item.miles)?.reduce((prev: number, curr: number) => prev + curr, 0)
    
        return currency(bid?.data?.amount).subtract(usedMiles).value
    }, [bid?.data])

    const { amount, sell_price, fees, additional_cost } = formik.values
    const { setErrors }  = formik

    useEffect(() => {
        if (Number(amount) > Number(milesAvailable)) {
            setErrors({ amount: 'A quantidade de milhas deve ser menor que o disponível na oferta' })
        }
    }, [amount, milesAvailable, setErrors])

    const messageText = encodeURI(`Olá, ${bid?.data?.user?.name}! Tudo bem? Me chamo ${me?.name} e estou entrando em contato para falar da sua oferta número ${bid?.data?.id} na ${APP_NAME}.`)

    const soldCostPerK = useMemo(() => {
        const totalMilesCost = currency(sell_price).subtract(fees).subtract(additional_cost).value
        return currency(totalMilesCost).divide(currency(amount, {precision: 3}).divide(1000)).value
    }, [additional_cost, amount, fees, sell_price])

    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5} >
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1}>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={12} marginBottom={2}>
                            <Typography textAlign={"center"} variant="h5">Cadastrar Emissão</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DatePicker 
                                label="Data do Voo"
                                value={formik.values.flight_date ? moment(formik.values.flight_date) : null}
                                onChange={(value) => {
                                    formik.setFieldValue('flight_date', value?.toDate(), true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}> 
                            <Autocomplete 
                                label="Companhia"
                                onChange={({value}) => {
                                    formik.setFieldValue('airline', value, true)
                                }}
                                options={airlineOptions}
                                onBlur={formik.handleBlur}
                                value={airlineOptions.find(item => item.value === formik.values.airline)}
                                helperText={formik.touched.airline && formik.errors.airline}
                                error={!!formik.touched.airline && !!formik.errors.airline}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Emissor"
                                name="author_name"
                                onChange={formik.handleChange}
                                value={formik.values.author_name}
                                helperText={formik.touched.author_name && formik.errors.author_name}
                                error={!!formik.touched.author_name && !!formik.errors.author_name}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Localizador"
                                name="reservation_code"
                                onChange={event => {
                                    formik.setFieldValue('reservation_code' ,event.target.value.toUpperCase().trim())
                                }}
                                value={formik.values.reservation_code}
                                helperText={formik.touched.reservation_code && formik.errors.reservation_code}
                                error={!!formik.touched.reservation_code && !!formik.errors.reservation_code}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Passageiros"
                                name="pax"
                                select
                                options={paxOptions}
                                onChange={formik.handleChange}
                                value={formik.values.pax}
                                helperText={formik.touched.pax && formik.errors.pax}
                                error={!!formik.touched.pax && !!formik.errors.pax}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumericInput
                                label="Milhas"
                                name="amount"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.amount}
                                helperText={formik.touched.amount && formik.errors.amount}
                                error={!!formik.touched.amount && !!formik.errors.amount}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CurrencyInput
                                label="Taxas (R$)"
                                name="fees"
                                onChangeValue={(_, originalValue) => {
                                    formik.setFieldValue('fees', originalValue, true)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.fees}
                                helperText={formik.touched.fees && formik.errors.fees}
                                error={!!formik.touched.fees && !!formik.errors.fees}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Bagagem despachada, marcação de assentos, etc..." >
                                <div>
                                <CurrencyInput
                                    label="Custos Adicionais (R$)"
                                    name="additional_cost"
                                    onChangeValue={(_, originalValue) => {
                                        formik.setFieldValue('additional_cost', originalValue, true)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.additional_cost}
                                    helperText={formik.touched.additional_cost && formik.errors.additional_cost}
                                    error={!!formik.touched.additional_cost && !!formik.errors.additional_cost}
                                />
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CurrencyInput
                                label="Valor de Venda (R$)"
                                name="sell_price"
                                onChangeValue={(_, originalValue) => {
                                    formik.setFieldValue('sell_price', originalValue, true)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.sell_price}
                                helperText={formik.touched.sell_price && formik.errors.sell_price}
                                error={!!formik.touched.sell_price && !!formik.errors.sell_price}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Cartão utilizado para pagamento das taxas." placement="top">
                                <div>
                            <Autocomplete 
                                label="Cartão"
                                onChange={({value}) => {
                                    formik.setFieldValue('card_id', value, true)
                                }}
                                options={CARDS_OPTIONS}
                                onBlur={formik.handleBlur}
                                value={CARDS_OPTIONS.find((item: { value: string }) => item.value === formik.values.card_id) || null}
                                helperText={formik.touched.card_id && formik.errors.card_id}
                                error={!!formik.touched.card_id && !!formik.errors.card_id}
                            />
                            </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Caso haja mais de um passageiro, insira o nome de apenas um deles." placement="top">
                                <div>
                            <Input
                                label="Nome do Passageiro"
                                name="pax_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pax_name}
                                helperText={formik.touched.pax_name && formik.errors.pax_name}
                                error={!!formik.touched.pax_name && !!formik.errors.pax_name}
                            />
                            </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                label="Aeroporto de Origem"
                                name="origin_airport_code"
                                onChange={e => {
                                    formik.setFieldValue('origin_airport_code', e.target.value.replace(/([^A-Z])/ig, '').toLocaleUpperCase().slice(0, 3), true)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.origin_airport_code}
                                helperText={formik.touched.origin_airport_code && formik.errors.origin_airport_code}
                                error={!!formik.touched.origin_airport_code && !!formik.errors.origin_airport_code}
                            />
                        </Grid>
                        {formik.values.card_id && (<Grid item textAlign="left" xs={12}>
                            <Button variant="text" size="small" onClick={creditCardModalVisibilityControls.setTrue}>
                                <CreditCardIcon style={{marginRight: 5}} />
                                Ver dados do cartão
                            </Button>
                        </Grid>)}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Custo Total:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="end">
                                        {formatMoney(totalCost)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Lucro Total:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="end">
                                        {formatMoney(currency(formik.values.sell_price).subtract(totalCost).value)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {me?.role === 'ADMIN' && (<Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Milheiro Vendido:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="end">
                                        {
                                            formatMoney(soldCostPerK)
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>)}
                        <Grid item marginTop={2}>
                            <Button disabled={!formik.isValid} type="submit">Cadastrar</Button>
                        </Grid>
                    </Grid>
                    </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Detalhes da Oferta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            ID:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Link href={`/oferta/${bid?.data?.id}`}>
                                            # {bid?.data?.id}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Data:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography textAlign="end">
                                            {moment(bid?.data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Fornecedor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.user?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Companhia:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography textAlign="end">
                                            {COMPANY_OPTIONS.find(item => item.value === bid?.data?.company)?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Quantidade:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Typography textAlign="end">
                                            {Number(milesAvailable).toLocaleString('pt-BR')}
                                        </Typography>
                                        {milesAvailable < Number (bid?.data?.amount) && (<Typography textAlign="end" marginLeft={1} color="red" fontWeight="bold">
                                            (-{Number(currency(bid?.data?.amount).subtract(milesAvailable).value).toLocaleString('pt-BR')})
                                        </Typography>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            CPFs Disponíveis:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Typography textAlign="end">
                                            {bid?.data?.pax === null ? '∞' : Number(paxOptions[paxOptions.length - 1]?.value || 0)}
                                        </Typography>
                                        {bid?.data?.pax !== null && Number(paxOptions[paxOptions.length - 1]?.value || 0) < bid?.data?.pax && (
                                            <Typography textAlign="end" marginLeft={1} color="red" fontWeight="bold">
                                                (-{currency(bid?.data?.pax).subtract(Number(paxOptions[paxOptions.length - 1]?.value || 0))?.value})
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            Valor do Milheiro:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography textAlign="end">
                                            {formatMoney(bid?.data?.price_per_k)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            Aceita Pag. a Prazo:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography textAlign="end">
                                            {bid?.data?.accepts_custom_payment ? `Sim (${bid?.data.custom_payment_days || 2} dias)` : 'Não'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            Emissões da Oferta:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} textAlign="end">
                                        {
                                            bid?.data?.orders ? bid?.data?.orders?.map((item: {id: string}) => {
                                                return (
                                                    <Fragment>
                                                        &nbsp;&nbsp;
                                                        <Link href={`/emissoes/${item.id}`}>
                                                            # {item.id}
                                                        </Link>
                                                    </Fragment>
                                                )
                                            }) : (
                                                <Typography>
                                                    -
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Contatos:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="right">
                                        <ButtonGroup>
                                            <Button 
                                                variant="outlined"
                                                onClick={() => 
                                                    window.open(`https://api.whatsapp.com/send?phone=${bid?.data?.user?.whatsapp}&text=${messageText}`, '_blank')
                                                }
                                            >
                                                <WhatsAppIcon />
                                            </Button>
                                            <Button 
                                                variant="outlined"
                                                onClick={() => 
                                                    window.open(`https://t.me/${bid?.data?.user?.telegram.replace('@', '')}?text=${messageText}`, '_blank')
                                                }
                                                disabled={!bid?.data?.user?.telegram}
                                            >
                                                <TelegramIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados da Conta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Login:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.account?.login}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Senha:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                            <Typography textAlign="end">
                                        {
                                            passwordData ? (
                                                passwordData.password
                                            ) : (
                                            <div style={{cursor: 'pointer'}} onClick={handleRevealPassword}>
                                            <VisibilityIcon fontSize="inherit" />
                                            </div>
                                            )
                                        }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Contrassenha:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                            <Typography textAlign="end">
                                        {
                                            passwordData ? (
                                                passwordData.secondary_password ?? '-'
                                            ) : (
                                            <div style={{cursor: 'pointer'}} onClick={handleRevealPassword}>
                                                <VisibilityIcon fontSize="inherit" />
                                            </div>
                                            )
                                        }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Nome do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.account?.holder_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            E-mail do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.data?.account?.holder_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            CPF do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatCPF(bid?.data?.account?.holder_cpf || '')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Data de Nasc. do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatDate(bid?.data?.account?.holder_birthday || '')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {formik.values.card_id && (<CreditCardModal
                open={creditCardModalVisibility}
                onClose={creditCardModalVisibilityControls.setFalse} 
                cardId={Number(formik.values.card_id)}
            /> )}
        </Layout>
    )
}