import { ThemeProvider } from './components';
import { AuthContextProvider } from './context/auth';
import RouterProvider from './routes'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack'
import {useEffect} from 'react'
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA_ID as string);

function App() {
  useEffect(() => {
    if (performance.navigation.type === 2) {
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="br">
        <ThemeProvider>
          <AuthContextProvider>
              <RouterProvider />
          </AuthContextProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App
