import {Fragment, useContext} from 'react'
import { Grid, Loader, Paper, Table } from "../../../components"
import AuthContext from '../../../context/auth'
import { useAxios } from '../../../hooks'
import moment from 'moment'
import currency from 'currency.js'
import { COMPANY_OPTIONS } from '../../../constants'
import { formatMoney, getBidStatusColor, getBidStatusLabel, getOrderConfirmationColor, getOrderConfirmationLabel } from '../../../utils'
import { Chip, Link } from '@mui/material'

export const Orders = () => {
    const [userReferrals] = useAxios(`/user-referral/orders`)
    console.log('userReferrals', userReferrals)
    return (
        <Grid container justifyContent="center" paddingTop={1} gap={3} >
            {userReferrals.loading ? (
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Loader />
                </div>
            ) :(
            <Fragment> 
                <Grid item xs={12}>
                    <Paper elevation={1}>
                    <Table 
                        columns={[
                            { field: 'id', type: 'string', headerName: 'ID', width: 100, renderCell: ({value}) => (
                                <Link href={`/emissoes/${value}`}>
                                    {value}
                                </Link>
                            ) },
                            { field: 'created_at', type: 'dateTime', headerName: 'Data', flex: 1, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'bid', type: 'string', headerName: 'Usuário', flex: 1, valueGetter: ({value}) => value.user.name, renderCell: ({row}) => {
                                return (
                                    <Link href={`/usuarios/${row.bid.user.id}`}>
                                        {row.bid.user.name}
                                    </Link>
                                )
                            } },
                            { field: 'invited_by', type: 'string', headerName: 'Indicado por', flex: 1, valueGetter: ({row}) => row.bid.user.inviter.name, renderCell: ({row}) => {
                                return (
                                    <Link href={`/usuarios/${row.bid.user.inviter.id}`}>
                                        {row.bid.user.inviter.name}
                                    </Link>
                                )
                            } },
                            { field: 'company', type: 'string', headerName: 'Companhia', flex: 1, valueGetter: ({row}) =>  COMPANY_OPTIONS.find(item => item.value === row.bid.company)?.label },
                            { field: 'miles', type: 'number', headerName: 'Milhas', flex: 1, valueFormatter: ({value}) => {
                                return Number(value).toLocaleString('pt-BR')
                            }},
                            { field: 'Lucro', type: 'number', headerName: 'Lucro', flex: 1, valueGetter: ({row}) => {
                                const profit = currency(row.sell_price).subtract(row.total_cost).value

                                return profit
                            }, valueFormatter: ({value}) => {
                                return formatMoney(value)
                            }},
                            { field: 'confirmation', type: 'singleSelect', valueOptions: ['Pendente', 'Confirmada', 'Confirmada'], headerName: 'Status', flex: 1, valueGetter: ({value}) => getOrderConfirmationLabel(value), renderCell: ({value, row}) => <Chip color={getOrderConfirmationColor(row.confirmation)} label={value} /> },
                        ]}
                        rows={userReferrals.data || []}
                    />
                    </Paper>
                </Grid>
            </Fragment>
            )}
        </Grid>
    )
}