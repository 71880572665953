import { Button, Grid, Layout, Paper, Table, Loader, Autocomplete, Input, Typography, Alert, FileUploader } from "../../../components";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../../constants";
import { useAxios } from '../../../hooks'
import { Fragment, useContext, useEffect, useMemo } from "react";
import { Chip, Link, TextareaAutosize, Tooltip } from "@mui/material";
import { formatMoney, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import DownloadIcon from '@mui/icons-material/Download';
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import AuthContext from "../../../context/auth";
import { CreateSupportTicketValidationSchema } from "@next-pontos/validations";

export function NewHelp () {
    const navigate = useNavigate()
    const {me} = useContext(AuthContext)

    const [createdTicket, createTicket] = useAxios({
        url: '/support-ticket',
        method: 'POST'
    }, {
        manual: true
    })

    const [bids, refetchBids] = useAxios(`/bid`, {
        manual: true
    })

    const bidOptions = useMemo(() => {
        return (bids.data || []).map((item: {id: number, company: string, amount: number}) => {
            return {
                value: item.id,
                label: `#${item.id} - ${Number(item.amount).toLocaleString('pt-BR')} - ${COMPANY_OPTIONS.find(company => company.value === item.company)?.label}`
            }
        })
    }, [bids.data])
    
    const supportTicketFormik = useFormik({
        initialValues: {
            message: '',
            file: null,
            bid_id: '',
        },
        validationSchema: CreateSupportTicketValidationSchema,
        onSubmit: async values => {
            const formData = new FormData();

            formData.append('message', values.message)
            formData.append('bid_id', values.bid_id)

            if (values.file) {
                formData.append('file', values.file)
            }
            
            const { data, status } = await createTicket({
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status === 201) {
                enqueueSnackbar('Chamado criado com sucesso!', {
                    variant: 'success'
                })

                navigate(`/ajuda/${data.id}`)
            }
        }
    })

    useEffect(() => {
        if (me?.id) {
            refetchBids({
                url: `/bid`,
                params: {
                    user_id: me?.id
                }
            })
        }
    }, [me])

    return (
        <Layout>
            <Grid container justifyContent="center" padding={3} spacing={2} height="100%" alignItems="center">
                {(createdTicket.loading || bids.loading) ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (
                    <Fragment>
                        <Grid item xs={12} sm={4}>
                        <form onSubmit={supportTicketFormik.handleSubmit}>
                            <Paper elevation={1}>
                                <Grid container p={2} spacing={2}>
                                <Grid item xs={12} marginBottom={2}>
                                    <Typography textAlign={"center"} variant="h5">Novo Chamado</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert icon={false} color="info">
                                        Apenas selecione o campo de oferta caso o seu chamado seja referente à uma oferta. Caso contrário deixe o campo vazio.
                                    </Alert>
                                </Grid>
                                    <Grid item xs={12}> 
                                        <Tooltip
                                            title="Caso seu chamado seja sobre uma oferta específica, indique neste campo."
                                        >
                                            <Autocomplete
                                                label="Oferta"
                                                onChange={({value}) => {
                                                    supportTicketFormik.setFieldValue('bid_id', value, true)
                                                }}
                                                options={bidOptions}
                                                onBlur={supportTicketFormik.handleBlur}
                                                value={bidOptions.find((item: { value: string }) => item.value === supportTicketFormik.values.bid_id)}
                                                helperText={supportTicketFormik.touched.bid_id && supportTicketFormik.errors.bid_id}
                                                error={!!supportTicketFormik.touched.bid_id && !!supportTicketFormik.errors.bid_id}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Input 
                                            name="message"
                                            label="Mensagem"
                                            multiline
                                            maxRows={4}
                                            rows={4}
                                            onChange={supportTicketFormik.handleChange}
                                            onBlur={supportTicketFormik.handleBlur}
                                            helperText={supportTicketFormik.touched.message && supportTicketFormik.errors.message}
                                            error={!!supportTicketFormik.touched.message && !!supportTicketFormik.errors.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <FileUploader 
                                            label="Anexo"
                                            onChange={(value) => {
                                                supportTicketFormik.setFieldValue('file', value, true)
                                            }}
                                            value={supportTicketFormik.values.file}
                                            inputProps={{
                                                accept: 'image/*, .pdf'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} textAlign="center"> 
                                        <Button type="submit"> 
                                            Enviar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            </form>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Layout>
    )
}