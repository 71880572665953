import { enqueueSnackbar } from 'notistack';
import Axios, { AxiosError, AxiosHeaders } from 'axios' 

export const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
})

API.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('@next-pontos/token')

    if (token) {
        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`)
    }

    return config 
})

API.interceptors.response.use(success => success, (error: AxiosError<{ message?: string }>) => {
    if ([403, 409].includes(error.response?.status as number)) {
        enqueueSnackbar(error.response?.data.message || 'Erro interno! Entre em contato com o administrador do sistema', {
            variant: 'error'
        })
        
        return Promise.resolve(error)
    }

    if (error.response?.status === 401) {
        window?.localStorage?.removeItem('@next-pontos/token')
        
        if (window.location.pathname !== '/' && window.location.pathname !== '/captacao' && window.location.pathname !== '/cadastro' && window.location.pathname !== '/esqueci-minha-senha' && window.location.pathname !== '/nova-senha') {
            window.location.href = '/'
        }
    }
    
    return Promise.reject(error)
})

export default API