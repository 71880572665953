import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EqualizerIcon from '@mui/icons-material/Equalizer'
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person';
import { Badge, BadgeProps, Fab, Tooltip, useTheme } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AuthContext from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import LayoutBackgroundImage from './layout-background.png'
import useAxios from 'axios-hooks'
import moment from 'moment';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PhoneIcon from '@mui/icons-material/Phone';
import TelegramIcon from '@mui/icons-material/Telegram';
import SosIcon from '@mui/icons-material/Sos';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { APP_NAME, APP_CONTACTS, APP_DOMAIN } from '@next-pontos/settings';
import ShareIcon from '@mui/icons-material/Share';
import { enqueueSnackbar } from 'notistack';
import { Loader } from '../Loader'
import StorefrontIcon from '@mui/icons-material/Storefront';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Share, SvgIconComponent } from '@mui/icons-material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type LayoutMenuItem = {
  label: string,
  tooltip: string,
  Icon: SvgIconComponent,
  badgeProps?: BadgeProps,
  onClick?: () => void
  href: string, 
  target?: string
}

export function Layout({children}: {children: React.ReactNode}) {
  const [open, setOpen] = React.useState(false);
  const { me, signOut } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const theme = useTheme()

  const [payments, getPayments] = useAxios('/payment', {
    manual: true,
    autoCancel: false
  })

  const [orders, getOrders] = useAxios('/order', {
    manual: true,
    autoCancel: false
  })

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogoutClick = React.useCallback(() => {
    signOut(() => {
      window.location.href = '/'
    })
  }, [signOut, navigate])
  
  const activeListItemStyles = React.useCallback((url: string) => {
    let basePathName = `/${window.location.pathname.split('/')[1]}`
    
    return url.includes(basePathName) ? {
      color: theme.palette.primary.main
    }: {}
  }, [theme])

  React.useEffect(() => {
    if (me) {
      if (me.is_first_login) {
        window.location.href = '/bem-vindo'
      }
    }
  }, [me])

  React.useEffect(() => {
    if (me?.role === 'ADMIN' && !payments.loading) {
      getPayments()
      getOrders()
    }
  }, [me?.role])

  const userMenuItems: LayoutMenuItem[] = React.useMemo(() => {
    const menuItems: LayoutMenuItem[] = [
      { label: 'Home', tooltip: 'Home', Icon: EqualizerIcon, href: '/home' },
      { label: 'Minhas Ofertas', tooltip: 'Minha Ofertas', Icon: ChecklistRtlIcon, href: '/minhas-ofertas' },
      { label: 'Meus Pagamentos', tooltip: 'Meus Pagamentos', Icon: PaymentsIcon, href: '/meus-pagamentos' },
      { label: 'Minhas Contas', tooltip: 'Minhas Contas', Icon: PeopleIcon, href: '/minhas-contas' },
    ]

    if (me?.user_module.is_referral_enabled) {
      menuItems.push({ label: 'Minhas Indicações', tooltip: 'Minhas Indicações', Icon: ShareIcon, href: '/minhas-indicacoes' })
    }

    menuItems.push({ label: 'Meu Perfil', tooltip: 'Meu Perfil', Icon: PersonIcon, href: '/meu-perfil', badgeProps: !me?.pix_key ? { color: 'error', badgeContent: '!' } : undefined })

    return menuItems
  }, [me?.pix_key, me?.user_module.is_referral_enabled])

  const adminMenuItems: LayoutMenuItem[] = React.useMemo(() => {
    const menuItems: LayoutMenuItem[] = []

    if (me?.role === 'ADMIN') {
      menuItems.push({ label: 'Dashboard', tooltip: 'Dashboard', Icon: EqualizerIcon, href: '/dashboard' })
      menuItems.push({ label: 'Usuários', tooltip: 'Usuário', Icon: PeopleIcon, href: '/usuarios'})
    }

    if (me?.role === 'ADMIN' || me?.role === 'SELLER' || me?.role === 'AGENCY') {
      menuItems.push({ label: 'Ofertas', tooltip: 'Ofertas', Icon: ChecklistRtlIcon, href: '/ofertas' })

      menuItems.push({ 
        label: 'Emissões', 
        tooltip: 'Emissões', 
        Icon: AirplaneTicketIcon, 
        href:'/emissoes', 
        badgeProps: orders?.data?.find((item: {confirmation: string, created_at: Date}) => item.confirmation === 'PENDING' && moment(item.created_at).isBefore(moment().subtract(48, 'hours'))) 
          ? { color: 'error', badgeContent: '!' } 
          : undefined 
      })
    }

    if (me?.role === 'ADMIN') {
      menuItems.push({ label: 'Indicações', tooltip: 'Indicações', Icon: ShareIcon, href: '/indicacoes' })

      menuItems.push({
        label: 'Financeiro',
        tooltip: 'Financeiro',
        Icon: AccountBalanceIcon,
        href: '/financeiro',
        badgeProps: payments?.data?.find((item: {status: string}) => item.status === 'LATE')
          ? { color: 'error', badgeContent: '!' }
          : undefined
      })

      menuItems.push({ label: 'Agências', tooltip: 'Agência', Icon: StorefrontIcon, href: '/agencias'})
      menuItems.push({ label: 'Leads', tooltip: 'Leads', Icon: AdsClickIcon, href: '/leads' })
      menuItems.push({ label: 'Cupons', tooltip: 'Cupons', Icon: LocalOfferIcon, href: '/cupons' })
      menuItems.push({ label: 'Cartões', tooltip: 'Cartões', Icon: CreditCardIcon, href: '/cartoes' })
      menuItems.push({ label: 'Programas', tooltip: 'Programas', Icon: LoyaltyIcon, href: '/programas' })
      menuItems.push({ label: 'Chamados', tooltip: 'Chamados', Icon: SosIcon, href: '/chamados' })
    }

    return menuItems
  }, [me?.role, orders?.data, payments?.data])

  if (!me) {
    return (
      <Loader />
    )
  }
  
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" display="flex" flex={1}>
              Bem-vindo(a) à {APP_NAME}, {me?.name}!
            </Typography>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column'
              }}
            >
              <Typography variant="caption" noWrap component="div" display="flex" >
                <PhoneIcon fontSize='small' style={{marginRight: 10}}/> {`+${APP_CONTACTS.whatsapp.slice(0, 2)} ${APP_CONTACTS.whatsapp.slice(2, 4)} ${APP_CONTACTS.whatsapp.slice(4, 9)}-${APP_CONTACTS.whatsapp.slice(9, 13)}`}
              </Typography>
              <Typography variant="caption" noWrap component="div" display="flex" >
                <TelegramIcon fontSize='small' style={{marginRight: 10}}/> {`@${APP_CONTACTS.telegram}`}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <div style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <img alt="logo" src="/logo.png" height={50} />
            </div>
            {open && (<IconButton onClick={handleDrawerClose}>
              {!open ? <MenuIcon /> : <ChevronLeftIcon />}
            </IconButton>)}
          </DrawerHeader>
          <Divider />
          <List>
            {
              userMenuItems.map(menuItem => {
                const Icon = menuItem.Icon
                
                return (
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        ...(menuItem.href ? activeListItemStyles(menuItem.href) : {})
                      }}
                      href={menuItem.href}
                    >
                    <Tooltip title={menuItem.tooltip}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {
                          menuItem.badgeProps ? (
                            <Badge {...menuItem.badgeProps}>
                              <Icon style={activeListItemStyles(menuItem.href)}/>
                            </Badge>
                          ) : (
                            <Icon style={activeListItemStyles(menuItem.href)}/>
                          )
                        }
                      </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={menuItem.label} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                )
              })
            }
          </List>
          <Divider />            
          {
            (me?.role === 'ADMIN' || me?.role === 'SELLER' || me.role === 'AGENCY') && (
              <React.Fragment>
                <List>
                {
                  adminMenuItems.map(menuItem => {
                    const Icon = menuItem.Icon
                      
                      return (
                        <ListItem disablePadding sx={{ display: 'block' }}>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? 'initial' : 'center',
                              px: 2.5,
                              ...(menuItem.href ? activeListItemStyles(menuItem.href) : {})
                            }}
                            href={menuItem.href}
                          >
                          <Tooltip title={menuItem.tooltip}>
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              {
                                menuItem.badgeProps ? (
                                  <Badge {...menuItem.badgeProps}>
                                    <Icon style={activeListItemStyles(menuItem.href)}/>
                                  </Badge>
                                ) : (
                                  <Icon style={activeListItemStyles(menuItem.href)}/>
                                )
                              }
                            </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={menuItem.label} sx={{ opacity: open ? 1 : 0 }} />
                          </ListItemButton>
                        </ListItem>
                      )
                  })
                }
                </List>
                <Divider />
              </React.Fragment>
            )
          }
          <List
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {APP_CONTACTS.feedback_form_link ? (<ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                href={APP_CONTACTS.feedback_form_link}
                target="_blank"
              >

              <Tooltip title='Feedback'>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <FeedbackIcon />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary='Feedback' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>) : null}
            <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                ...activeListItemStyles('/ajuda')
              }}
              href={'/ajuda'}
            >
              <Tooltip title='Ajuda'>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <QuestionAnswerIcon style={activeListItemStyles('/ajuda')} />
                </ListItemIcon>
                </Tooltip>
                <ListItemText primary='Ajuda' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={onLogoutClick}
              >
              <Tooltip title='Logout'>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon></Tooltip>
                <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <div style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'end',
              position: 'relative'
            }}>
              <img alt="logo" src="/logo-square.png" height={50} />
            </div>
          </List>
        </Drawer>
        <Box component="main" sx={{ 
          width: '100%',
          // backgroundImage: `url(logo.png)`, backgroundPosition: 'center', backgroundSize: '80%', backgroundRepeat: 'no-repeat', 
          height: 'calc(100vh - 64px)',
          }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </React.Fragment>
  );
}
