import { Grid, Menu, Layout, Paper, Table, ButtonGroup, Loader, Typography, Alert, MultipleAutocomplete, MultipleAutocompleteOption, DatePicker } from "../../../components";
import useSWR from 'swr'
import API from "../../../API";
import moment from "moment";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Chip, Link, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDate, formatMoney, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import { useAxios } from "../../../hooks";
import { Fragment, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { groupBy, sum, sumBy } from "lodash";
import { Line } from "recharts";
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";

const today = moment().toDate()
const oneMonth = moment().add(15, 'days').toDate()

const diff = moment(oneMonth).diff(today, 'days')

const month: Array<Date> = []

for (let day = 0; day < diff; day++) {
    month.push(moment(today).add(day, 'day').toDate())
}

const statusOptions = [
    { value: 'AWAITING_ORDER_CONFIRMATION', label: 'Aguardando Confirmação' },
    { value: 'CANCELLED', label: 'Cancelado' },
    { value: 'PENDING', label: 'Pendente' },
    { value: 'PAID', label: 'Pago' },
    { value: 'LATE', label: 'Atrasado' },
]

function StatusSelector(props: GridFilterInputValueProps) {
    const { item, applyValue } = props;
  
    return (
        <MultipleAutocomplete
        variant="standard"
            label="Status"
            options={statusOptions}
            value={statusOptions.filter(option => item?.value?.includes(option.value))}
            onChange={(value) => {
                applyValue({
                    ...item,
                    value: (value as unknown as MultipleAutocompleteOption[]).map(item => item.value)
                })
            }}
        />
    );
  }
  
const statusSelectorOperator: GridFilterOperator[] = [
{
    label: 'é igual a',
    value: 'in',
    getValueAsString: (values) => {
        return values.map((item: string) => statusOptions.find(({value}) => value === item)?.label).join(', ')
    },
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length === 0 || !filterItem.value) {
            return null;
        }

        return ({ value }) => {
            return filterItem.value.includes(value)
        };
    },
    InputComponent: StatusSelector,
}]; 

export function Payments () {
    const navigate = useNavigate()

    const [payments] = useAxios(`/payment`)
    const [updatedPayment, updatePayment] = useAxios({
        url: `/payment`,
        method: 'POST'
    }, {
        manual: true
    })

    const dash = useMemo(() => {
        return month.map(item => {
            const items = (payments.data || [])
                ?.filter((payment: { due_date: Date }) => moment(payment.due_date).isSame(item, 'date'))
                ?.filter((payment: { status: string }) => payment.status !== 'PAID' && payment.status !== 'CANCELLED')
                ?.map((item: { amount: string }) => Number(item.amount))

            return {
                due_date: formatDate(item),
                amount: sum(items)
            }
        })
    }, [payments.data])

    return (
        <Grid container justifyContent="center" /*minHeight={"calc(100vh - 64px)"}*/ padding={3} gap={3} >
            {(payments.loading) ? (
                <div style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Loader />
                </div>
            ) :(
            <Fragment> 
                <Grid item xs={12} sm={10}>
                        <Alert color="info" icon={false}>
                            Esta listagem inicialmente apenas mostra pagamentos com status <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Atrasado</span> e <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Pendente</span>.<br/>Para ver todos os registros, limpe os filtros da tabela.
                        </Alert>
                    </Grid>
                <Grid xs={12} sm={10}> 
                    <Alert 
                        icon={false}
                    >
                        <strong>Próximos Pagamentos:</strong>
                        <ul>
                            {
                                dash.map(item => {
                                    return (
                                        <li>
                                            <strong>{item.due_date}</strong>: {formatMoney(item.amount)}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Alert>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Paper elevation={1}>
                    <Table 
                        rowHeight={65}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [
                                        { 
                                            field: 'status',
                                            operator: 'in',
                                            value: ['PENDING', 'LATE']
                                        }
                                    ]
                                }
                            }
                        }}
                        columns={[
                            { field: 'id', headerName: 'ID', width: 50 },
                            { field: 'created_at', headerName: 'Data', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            } },
                            { field: 'due_date', headerName: 'Vencimento', width: 200, valueFormatter: ({value}) => {
                                return moment(value).format('DD/MM/YYYY HH:mm:ss')
                            }, renderCell: ({value, row}) => {
                                return (
                                    <DatePicker 
                                        disabled={row.status === 'PAID' || row.status === 'CANCELLED'}
                                        onChange={async (newValue) => {
                                            const {status} = await  updatePayment({
                                                url: `/payment/${row.id}`,
                                                method: 'POST',
                                                data: {
                                                    due_date: newValue
                                                }
                                            })

                                            if (status === 204) {
                                                enqueueSnackbar('Data de vencimento atualizada com sucesso!', {
                                                    variant: 'success'
                                                })
                                            }
                                        }}
                                        value={moment(value)}
                                    />
                                )
                            } },
                            { field: 'order_id', headerName: 'Emissão', width: 120, renderCell: ({value}) => {
                                return (
                                    <Link href={`/emissoes/${value}`}>
                                        # {value}
                                    </Link>
                                )
                            }},
                            {
                                field: 'supplier_name', headerName: 'Fornecedor', flex: 1
                            },
                            { field: 'amount', headerName: 'Valor', width: 120, valueFormatter: ({value }) => {
                                return formatMoney(value)
                            }},
                            {
                                field: 'status', headerName: 'Status', flex: 1, 
                                valueFormatter: ({value}) => {
                                    return getPaymentStatusLabel(value)
                                },
                                renderCell: ({ formattedValue, value }) => {
                                    return (
                                        <Chip color={getPaymentStatusColor(value)} label={formattedValue} />
                                    )
                                },
                                filterOperators: statusSelectorOperator
                            },
                            {
                                field: 'actions', headerName: '', sortable: false, filterable: false, disableColumnMenu: true, renderCell: ({row}) => {
                                    return (
                                        <ButtonGroup>
                                            <Menu
                                                disabled={row.status === 'AWAITING_ORDER_CONFIRMATION'}
                                                options={[
                                                    { 
                                                        label: 'Editar Pagamento', 
                                                        onClick: async () => {
                                                            navigate(`/pagamentos/${row.id}`)
                                                        }
                                                    }
                                                ]}
                                            >
                                                <MoreHorizIcon />
                                            </Menu>
                                        </ButtonGroup>
                                    )
                                }
                            }
                        ]}
                        rows={(payments?.data || []).map((item: { order: { bid: { user: { name: string }}}}) => ({
                            ...item,
                            supplier_name: item?.order?.bid?.user?.name
                        }))}
                    />
                    </Paper>
                </Grid>
            </Fragment>
            )}
        </Grid>
    )
}