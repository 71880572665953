import { Menu, ButtonGroup, Grid, Layout, Paper, Table, Loader, Checkbox, Input, CurrencyInput, NumericInput, Typography, Button, Modal } from "../../components";
import useSWR from 'swr'
import API from "../../API";
import moment from "moment";
import { COMPANY_OPTIONS } from "../../constants";
import { ChangeEvent, Fragment, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import AuthContext from "../../context/auth";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useNavigate} from 'react-router-dom'
import { useAxios, useToggle } from "../../hooks";
import { debounce } from "lodash";
import { PriceSetting, PriceSettingsInput } from "./PriceSettingsInput";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton } from "@mui/material";
import { useFormik } from "formik";

export const Programs = memo(function () {
    const navigate = useNavigate()

    const [programs, refetchProgram] = useAxios(`/program`)
    const [updatedProgram, updateProgram] = useAxios({
        method: 'PUT',
    }, {
        manual: true
    })
    const [programIdBeingEdited, setProgramIdBeingEdited] = useState('')
    const [isEditCustomDisclaimerModalVisible, isEditCustomDisclaimerModalVisibleControls] = useToggle(false)
    
    const update = useCallback(async (company: string, data: { custom_bid_disclaimer?: string, custom_payment_days?: number,  accept_custom_payment_days?: boolean, agency_spread?: number | string, avg_price_base?: number | string, min_amount?: number | string, active?: boolean, sell_price?: number | string, program_price_setting?: Array<PriceSetting & { direction: 'SELL' | 'BUY' }>}) => {
        await updateProgram({
            url: `/program/${company}`,
            method: 'PUT',
            data
        })

        refetchProgram()
    }, [refetchProgram, updateProgram])

    const debouncedUpdate = useMemo(() => debounce(update, 1000), [update])

    const formik = useFormik({
        initialValues: {
            custom_bid_disclaimer: ''
        },
        onSubmit: async (values) => {
            await update(programIdBeingEdited, {
                custom_bid_disclaimer: values.custom_bid_disclaimer
            })

            isEditCustomDisclaimerModalVisibleControls.setFalse()
            setProgramIdBeingEdited('')
        }
    })

    const program = useMemo(() => {
        return programs?.data?.find((item: { company: string }) => item.company === programIdBeingEdited)
    }, [programIdBeingEdited, programs?.data])

    useEffect(() => {
        if (program) {
            formik.resetForm({
                values: {
                    custom_bid_disclaimer: program?.custom_bid_disclaimer
                },
            })
        }
    }, [program])

    return (
        <Fragment>
            <Modal
                open={isEditCustomDisclaimerModalVisible}
                onClose={isEditCustomDisclaimerModalVisibleControls.setFalse}
                title="Editar Aviso na Oferta"
                bodyCustomCss={{
                    width: 500,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: formik.isSubmitting ? 'flex' : undefined
                }}
                onConfirm={!formik.isSubmitting ? formik.submitForm : undefined}
                confirmLabel="Salvar"
            >
                {!formik.isSubmitting ? (<div>
                    <Input 
                        name="custom_bid_disclaimer"
                        label="Aviso"
                        multiline
                        rows={4}
                        value={formik.values.custom_bid_disclaimer}
                        onChange={formik.handleChange}
                    />
                </div>) : (
                    <Loader />
                )}
            </Modal>
            <Layout>
                <Grid container justifyContent="center" minHeight={"calc(100vh - 64px)"} padding={3} >
                    {programs.loading || updatedProgram.loading ? (
                        <div style={{
                            display: 'flex',
                            flex: '1',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Loader />
                        </div>
                    ) :(<Grid item xs={12} sm={10}>
                        <Paper elevation={1}>
                        <Table 
                            getRowId={row => row.company}
                            rowHeight={65}
                            hideFooter
                            disableColumnFilter
                            disableColumnMenu
                            columns={[
                                { field: 'company', headerName: 'Programa', width: 200, sortable: false, valueGetter: ({value}) => {
                                    return COMPANY_OPTIONS.find(item => item.value === value)?.label
                                } },
                                { field: 'sell_price', headerName: 'Valor de Venda (R$)',sortable: false, flex: 1, 
                                valueFormatter: ({value}) => Number(value), 
                                renderCell: ({ formattedValue, row }) => {
                                    return (
                                        <CurrencyInput
                                            onChangeValue={(_, originalValue) => {
                                                debouncedUpdate(row.company, { sell_price: originalValue })
                                            }}
                                            value={formattedValue}
                                        />
                                    )
                                } },
                                { field: 'avg_price_base', headerName: 'Valor de Compra (R$)',sortable: false, flex: 1, 
                                valueFormatter: ({value}) => Number(value), 
                                renderCell: ({ row }) => {
                                    return (
                                        <PriceSettingsInput 
                                            value={row.program_price_settings}
                                            onChange={value => {
                                                update(row.company, {
                                                    program_price_setting: value.map((item) => ({
                                                        ...item,
                                                        direction: 'BUY'
                                                    }))
                                                })
                                            }}
                                        />
                                    )
                                } },
                                { field: 'agency_spread', headerName: 'Spread para Agências (R$)',sortable: false, flex: 1, 
                                valueFormatter: ({value}) => Number(value), 
                                renderCell: ({ formattedValue, row }) => {
                                    return (
                                        <CurrencyInput
                                            onChangeValue={(_, originalValue) => {
                                                debouncedUpdate(row.company, { agency_spread: originalValue })
                                            }}
                                            value={formattedValue}
                                        />
                                    )
                                } },
                                { field: 'min_amount', headerName: 'Quantidade Mínima de Compra', flex: 1, sortable: false, 
                                valueFormatter: ({value}) => Number(value), 
                                renderCell: ({formattedValue, row}) => {
                                    return (
                                        <NumericInput
                                            onChange={({target: { value }}) => {
                                                debouncedUpdate(row.company, { min_amount: Number(value) })
                                            }}
                                            value={formattedValue}
                                        />
                                    )
                                } },
                                { field: 'accept_custom_payment_days', headerName: 'Pag. à prazo', width: 100, sortable: false, renderCell: ({value, row}) => {
                                    return <Checkbox checked={value} onChange={({target: { checked }}) => {
                                        update(row.company, {
                                            accept_custom_payment_days: checked
                                        })
                                    }} />
                                }},
                                { field: 'custom_payment_days', headerName: 'Dias Para Pag. à prazo', flex: 1, sortable: false, 
                                valueFormatter: ({value}) => Number(value), 
                                renderCell: ({formattedValue, row}) => {
                                    return (
                                        <NumericInput
                                            onChange={({target: { value }}) => {
                                                debouncedUpdate(row.company, { custom_payment_days: Number(value) })
                                            }}
                                            value={formattedValue}
                                            disabled={!row.accept_custom_payment_days}
                                        />
                                    )
                                } },
                                { field: 'custom_bid_disclaimer', headerName: 'Aviso na Oferta', flex: 1, sortable: false, renderCell: ({value, row}) => {
                                    return (
                                        <Fragment>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    setProgramIdBeingEdited(row.company)
                                                    isEditCustomDisclaimerModalVisibleControls.setTrue()
                                                }}
                                            >
                                                <ModeEditIcon fontSize="small"/>
                                            </IconButton>
                                            <Typography>
                                                {value ? `${value.slice(0,10)}...`: ''}
                                            </Typography>
                                        </Fragment>
                                    )
                                }},
                                { field: 'active', headerName: 'Ativo', width: 100, sortable: false, renderCell: ({value, row}) => {
                                    return <Checkbox checked={value} onChange={({target: { checked }}) => {
                                        update(row.company, {
                                            active: checked
                                        })
                                    }} />
                                }},
                            ]}
                            rows={programs?.data || []}
                        />
                        </Paper>
                    </Grid>)}
                </Grid>
            </Layout>
        </Fragment>
    )
})