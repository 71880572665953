export const PAX_OPTIONS = [
    {
        value: '0',
        label: '∞'
    },
    ...(new Array(25)).fill(0).map((_, index) => {
        return {
            value: (index + 1).toString(),
            label: (index + 1).toString()
        }
    })
]

export const COMPANY_OPTIONS = [
    { value: 'GOL', label: 'Smiles', pax_options: PAX_OPTIONS.filter(item => Number(item.value) !== 0), color: '#ff7020' },
    { value: 'GOL_A_PRAZO', label: 'Smiles à Prazo', pax_options: PAX_OPTIONS.filter(item => Number(item.value) !== 0), color: '#ff7020' },
    { value: 'AZUL', label: 'Tudo Azul', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0 || Number(item.value) <= 5), color: '#036cb6' },
    { value: 'LATAM', label: 'Latam Pass', pax_options: PAX_OPTIONS.filter(item => Number(item.value) !== 0), color: '#0f0050' },
    { value: 'TAP', label: 'TAP Miles & Go', pax_options: PAX_OPTIONS.filter(item => Number(item.value) !== 0 && Number(item.value) <= 10), color: '#72be44' },
    { value: 'IBERIA', label: 'Iberia Plus', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#d7192c' },
    { value: 'AMERICAN_AIRLINES', label: 'AAdvantage', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#0069b8' },
    { value: 'QATAR', label: 'Privilege Club', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#5c0931' },
    { value: 'LIVELO', label: 'Livelo', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#e00979' },
    { value: 'ESFERA', label: 'Esfera', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#fe0100' },
    { value: 'ITAU', label: 'Itaú', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#ec7001' },
    { value: 'INTERLINE', label: 'Azul Interline / Viagens', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#036cb6' },
    { value: 'CAIXA', label: 'Caixa', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#135b9e' },
    { value: 'LIFE_MILES', label: 'LifeMiles', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#ff0000' },
    { value: 'FLYING_BLUE', label: 'Flying Blue', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#051039' },
    { value: 'CONNECT_MILES', label: 'Connect Miles', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#0032a0' },
    { value: 'C6', label: 'C6', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#000000de' },
    { value: 'SUMA', label: 'SUMA', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#016fde' },
    { value: 'AEROPLAN', label: 'Aeroplan', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#f01428' },
    { value: 'QANTAS', label: 'Qantas', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#eb141c' },
    { value: 'ALL', label: 'All Accor', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#1e1852'},
    { value: 'BRB', label: 'BRB', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#273582'},
    { value: 'PORTO_SEGURO', label: 'Porto Seguro', pax_options: PAX_OPTIONS.filter(item => Number(item.value) === 0), color: '#00a1fc'},
]

export const CARD_BRAND_OPTIONS = [
    { value: 'AMEX', label: 'American Express' },
    { value: 'VISA', label: 'Visa' },
    { value: 'ELO', label: 'Elo' },
    { value: 'MASTERCARD', label: 'Mastercard' },
]

export const ROLE_OPTIONS = [
    { value: 'SELLER', label: 'Emissor' },
    { value: 'ADMIN', label: 'Administrador' },
    { value: 'USER', label: 'Usuário' },
    { value: 'AGENCY', label: 'Agência' },
]

export const SUPPORT_TICKET_STATUS = [
    { value: 'OPEN', label: 'Aberto' },
    { value: 'CLOSED', label: 'Fechado' },
]

export const AIRLINES_OPTIONS = [
    {
        value: "AUTOSTRAD",
        label: "Autostradale"
    },
    {
        value: "EASTMIDLAN",
        label: "East Midlands Trains"
    },
    {
        value: "FLIBCO",
        label: "Flibco"
    },
    {
        value: "SR",
        label: "Sundair"
    },
    {
        value: "LONGTEST",
        label: "Long iata code test"
    },
    {
        value: "SAGALES",
        label: "Sagales"
    },
    {
        value: "EUROLINES",
        label: "Eurolines"
    },
    {
        value: "ISILINES",
        label: "Isilines"
    },
    {
        value: "NOMAGO",
        label: "Nomago"
    },
    {
        value: "WR",
        label: "WestJet Encore"
    },
    {
        value: "CY",
        label: "Cyprus Airways"
    },
    {
        value: "FLIXTRAIN",
        label: "Flixtrain"
    },
    {
        value: "P2",
        label: "Air Kenya"
    },
    {
        value: "JY",
        label: "interCaribbean Airways"
    },
    {
        value: "UU",
        label: "Air Austral"
    },
    {
        value: "SZS",
        label: "Scandinavian Airlines Ireland"
    },
    {
        value: "GREYHOUND",
        label: "Greyhound"
    },
    {
        value: "QV",
        label: "Lao Airlines"
    },
    {
        value: "2N",
        label: "NextJet"
    },
    {
        value: "AH",
        label: "Air Algerie"
    },
    {
        value: "TQ",
        label: "Tandem Aero"
    },
    {
        value: "RM",
        label: "Armenia Aircompany"
    },
    {
        value: "NATEXPRESS",
        label: "National Express"
    },
    {
        value: "A0",
        label: "Avianca Argentina"
    },
    {
        value: "JG",
        label: "JetGo"
    },
    {
        value: "M8",
        label: "SkyJet Airlines"
    },
    {
        value: "M0",
        label: "Aero Mongolia"
    },
    {
        value: "5T",
        label: "Canadian North"
    },
    {
        value: "6D",
        label: "Pelita"
    },
    {
        value: "GU",
        label: "Avianca Guatemala"
    },
    {
        value: "XW",
        label: "NokScoot"
    },
    {
        value: "UA",
        label: "United Airlines"
    },
    {
        value: "X4",
        label: "Alaska Seaplanes X4"
    },
    {
        value: "ND",
        label: "FMI Air"
    },
    {
        value: "V0",
        label: "Conviasa"
    },
    {
        value: "XX",
        label: "Greenfly"
    },
    {
        value: "D7",
        label: "AirAsia X"
    },
    {
        value: "XJ",
        label: "Thai AirAsia X"
    },
    {
        value: "2P",
        label: "PAL Express"
    },
    {
        value: "BUTAAIR",
        label: "Buta Airways"
    },
    {
        value: "CU",
        label: "Cubana de Aviación"
    },
    {
        value: "VV",
        label: "Viva Air"
    },
    {
        value: "PQ",
        label: "SkyUp Airlines"
    },
    {
        value: "W3",
        label: "Arik Air"
    },
    {
        value: "PE",
        label: "People's Viennaline PE"
    },
    {
        value: "YD",
        label: "Gomelavia"
    },
    {
        value: "GJ",
        label: "Loong Air"
    },
    {
        value: "FLIXBUS",
        label: "Flixbus"
    },
    {
        value: "CHIHUAHUEN",
        label: "Transportes Chihuahuenses"
    },
    {
        value: "SNCB",
        label: "SNCB"
    },
    {
        value: "99",
        label: "Ciao Air"
    },
    {
        value: "S9",
        label: "Starbow Airlines"
    },
    {
        value: "D4",
        label: "Aerodart"
    },
    {
        value: "8G",
        label: "Mid Africa Aviation"
    },
    {
        value: "8B",
        label: "TransNusa"
    },
    {
        value: "AD",
        label: "Azul"
    },
    {
        value: "VT",
        label: "Air Tahiti"
    },
    {
        value: "KS",
        label: "Peninsula Airways"
    },
    {
        value: "KB",
        label: "Druk Air"
    },
    {
        value: "SX",
        label: "SkyWork Airlines"
    },
    {
        value: "OV",
        label: "SalamAir"
    },
    {
        value: "JI",
        label: "Meraj Air"
    },
    {
        value: "3I",
        label: "Air Comet Chile"
    },
    {
        value: "QA",
        label: "Click (Mexicana)"
    },
    {
        value: "9J",
        label: "Dana Airlines Limited"
    },
    {
        value: "A2",
        label: "Astra Airlines"
    },
    {
        value: "SF",
        label: "Tassili Airlines"
    },
    {
        value: "ICBUS",
        label: "IC Bus"
    },
    {
        value: "DEINBUS",
        label: "DeinBus"
    },
    {
        value: "ORESUNDST",
        label: "Oresundstag"
    },
    {
        value: "LEBUSDIR",
        label: "Le Bus Direct"
    },
    {
        value: "XU",
        label: "African Express"
    },
    {
        value: "CD",
        label: "Corendon Dutch Airlines B.V."
    },
    {
        value: "J5",
        label: "Alaska Seaplane Service"
    },
    {
        value: "LQ",
        label: "Lanmei Airlines"
    },
    {
        value: "SV",
        label: "Saudi Arabian Airlines"
    },
    {
        value: "LH",
        label: "Lufthansa"
    },
    {
        value: "LA",
        label: "LATAM Airlines"
    },
    {
        value: "QF",
        label: "Qantas"
    },
    {
        value: "0B",
        label: "Blue Air"
    },
    {
        value: "MV",
        label: "Air Mediterranean"
    },
    {
        value: "DI",
        label: "Norwegian Air UK"
    },
    {
        value: "RT",
        label: "JSC UVT Aero"
    },
    {
        value: "F3",
        label: "Flyadeal"
    },
    {
        value: "TRANSFERO",
        label: "Transfero"
    },
    {
        value: "Y2",
        label: "AirCentury"
    },
    {
        value: "9P",
        label: "Air Arabia Jordan"
    },
    {
        value: "4C",
        label: "LATAM Colombia"
    },
    {
        value: "A6",
        label: "Air Travel"
    },
    {
        value: "B4",
        label: "ZanAir"
    },
    {
        value: "T0",
        label: "Avianca Peru"
    },
    {
        value: "L7",
        label: "Lugansk Airlines"
    },
    {
        value: "YW",
        label: "Air Nostrum"
    },
    {
        value: "Y7",
        label: "NordStar Airlines"
    },
    {
        value: "NT",
        label: "Binter Canarias"
    },
    {
        value: "3U",
        label: "Sichuan Airlines"
    },
    {
        value: "11",
        label: "TUIfly (X3)"
    },
    {
        value: "00",
        label: "Anadolujet"
    },
    {
        value: "REGIOJETT",
        label: "Regiojet Train"
    },
    {
        value: "LT",
        label: "LongJiang Airlines"
    },
    {
        value: "XM",
        label: "Alitalia Express"
    },
    {
        value: "4D",
        label: "Air Sinai"
    },
    {
        value: "FK",
        label: "Africa West"
    },
    {
        value: "2U",
        label: "Air Guinee Express"
    },
    {
        value: "A7",
        label: "Calafia Airlines"
    },
    {
        value: "GG",
        label: "Air Guyane"
    },
    {
        value: "FL",
        label: "AirTran Airways"
    },
    {
        value: "TE",
        label: "FlyLal"
    },
    {
        value: "SE",
        label: "XL Airways France"
    },
    {
        value: "WF",
        label: "Widerøe"
    },
    {
        value: "S1",
        label: "Serbian Airlines"
    },
    {
        value: "II",
        label: "LSM International "
    },
    {
        value: "HN",
        label: "Hankook Airline"
    },
    {
        value: "NP",
        label: "Nile Air"
    },
    {
        value: "M1",
        label: "Maryland Air"
    },
    {
        value: "M2",
        label: "MHS Aviation GmbH"
    },
    {
        value: "IRCITYLINK",
        label: "Irish Citylink"
    },
    {
        value: "VRG",
        label: "Voyage Air"
    },
    {
        value: "WI",
        label: "White Airways"
    },
    {
        value: "NY",
        label: "Air Iceland Connect"
    },
    {
        value: "4Z",
        label: "Airlink (SAA)"
    },
    {
        value: "PT",
        label: "Red Jet Andes"
    },
    {
        value: "ZE",
        label: "Eastar Jet"
    },
    {
        value: "MI",
        label: "SilkAir"
    },
    {
        value: "QR",
        label: "Qatar Airways"
    },
    {
        value: "7F",
        label: "First Air"
    },
    {
        value: "HG",
        label: "Niki"
    },
    {
        value: "RJ",
        label: "Royal Jordanian"
    },
    {
        value: "NN",
        label: "VIM Airlines"
    },
    {
        value: "XQ",
        label: "SunExpress"
    },
    {
        value: "9U",
        label: "Air Moldova"
    },
    {
        value: "PM",
        label: "Canary Fly"
    },
    {
        value: "GK",
        label: "Jetstar Japan"
    },
    {
        value: "IN",
        label: "Nam Air"
    },
    {
        value: "OO",
        label: "SkyWest"
    },
    {
        value: "DJ",
        label: "AirAsia Japan"
    },
    {
        value: "OA",
        label: "Olympic Air"
    },
    {
        value: "ZM",
        label: "Air Manas"
    },
    {
        value: "H1",
        label: "Hahn Air"
    },
    {
        value: "LS",
        label: "Jet2"
    },
    {
        value: "PP",
        label: "Air Indus"
    },
    {
        value: "I8",
        label: "Izhavia"
    },
    {
        value: "W8",
        label: "Cargojet Airways"
    },
    {
        value: "ZT",
        label: "Titan Airways"
    },
    {
        value: "4T",
        label: "Belair"
    },
    {
        value: "QH",
        label: "Bamboo Airways"
    },
    {
        value: "I0",
        label: "LEVEL operated by Iberia"
    },
    {
        value: "KO",
        label: "Komiaviatrans"
    },
    {
        value: "LEOEXT",
        label: "LEOEXPRESS Train"
    },
    {
        value: "PJ",
        label: "Air Saint Pierre"
    },
    {
        value: "2I",
        label: "Star Peru"
    },
    {
        value: "IQ",
        label: "Qazaq Air"
    },
    {
        value: "VL",
        label: "Med-View Airline"
    },
    {
        value: "LEOEXB",
        label: "LEOEXPRESS Bus"
    },
    {
        value: "KJ",
        label: "British Mediterranean Airways"
    },
    {
        value: "BN",
        label: "Horizon Airlines"
    },
    {
        value: "LG",
        label: "Luxair"
    },
    {
        value: "O7",
        label: "Orenburzhye Airline"
    },
    {
        value: "QT",
        label: "TAMPA"
    },
    {
        value: "N7",
        label: "Nordic Regional Airlines"
    },
    {
        value: "FIUMICINO",
        label: "Fiumicino express"
    },
    {
        value: "3J",
        label: "Jubba Airways"
    },
    {
        value: "MARINO",
        label: "Marino Bus"
    },
    {
        value: "U1",
        label: "Aviabus"
    },
    {
        value: "0X",
        label: "Copenhagen Express"
    },
    {
        value: "3E",
        label: "Air Choice One"
    },
    {
        value: "MK",
        label: "Air Mauritius"
    },
    {
        value: "AP",
        label: "AlbaStar"
    },
    {
        value: "TERAVSN",
        label: "Terravision"
    },
    {
        value: "MU",
        label: "China Eastern Airlines"
    },
    {
        value: "EXPRTRANS",
        label: "Ekspres transfer"
    },
    {
        value: "7E",
        label: "Sylt Air"
    },
    {
        value: "CT",
        label: "Alitalia Cityliner"
    },
    {
        value: "OF",
        label: "Overland Airways"
    },
    {
        value: "WZ",
        label: "Red Wings"
    },
    {
        value: "LI",
        label: "Leeward Islands Air Transport"
    },
    {
        value: "ZH",
        label: "Shenzhen Airlines"
    },
    {
        value: "8Q",
        label: "Onur Air"
    },
    {
        value: "NE",
        label: "Nesma Air"
    },
    {
        value: "UI",
        label: "Auric Air"
    },
    {
        value: "PK",
        label: "Pakistan International Airlines"
    },
    {
        value: "EI",
        label: "Aer Lingus"
    },
    {
        value: "LP",
        label: "LATAM Peru"
    },
    {
        value: "U8",
        label: "TUS Airways"
    },
    {
        value: "TC",
        label: "Air Tanzania"
    },
    {
        value: "E5",
        label: "Air Arabia Egypt"
    },
    {
        value: "WO",
        label: "Swoop"
    },
    {
        value: "RC",
        label: "Atlantic Airways"
    },
    {
        value: "A5",
        label: "HOP!"
    },
    {
        value: "B9",
        label: "Air Bangladesh"
    },
    {
        value: "5Y",
        label: "Atlas Air"
    },
    {
        value: "6G",
        label: "Air Wales"
    },
    {
        value: "6K",
        label: "Asian Spirit"
    },
    {
        value: "R7",
        label: "Aserca Airlines"
    },
    {
        value: "VU",
        label: "Air Ivoire"
    },
    {
        value: "UM",
        label: "Air Zimbabwe"
    },
    {
        value: "NM",
        label: "Air Madrid"
    },
    {
        value: "FJ",
        label: "Fiji Airways"
    },
    {
        value: "JS",
        label: "Air Koryo"
    },
    {
        value: "MD",
        label: "Air Madagascar"
    },
    {
        value: "8D",
        label: "Astair"
    },
    {
        value: "OT",
        label: "Aeropelican Air Services"
    },
    {
        value: "RE",
        label: "Stobart Air"
    },
    {
        value: "5L",
        label: "Aerosur"
    },
    {
        value: "Z3",
        label: "Avient Aviation"
    },
    {
        value: "EC",
        label: "Avialeasing Aviation Company"
    },
    {
        value: "ZL",
        label: "Regional Express"
    },
    {
        value: "J8",
        label: "Berjaya Air"
    },
    {
        value: "IO",
        label: "IrAero"
    },
    {
        value: "AS",
        label: "Alaska Airlines"
    },
    {
        value: "7G",
        label: "Star Flyer"
    },
    {
        value: "2L",
        label: "Helvetic Airways"
    },
    {
        value: "CITYLINK",
        label: "Citylink"
    },
    {
        value: "FB",
        label: "Bulgaria Air"
    },
    {
        value: "OI",
        label: "Orchid Airlines"
    },
    {
        value: "APPENINO",
        label: "Appenino shuttle "
    },
    {
        value: "EO",
        label: "Pegas Fly"
    },
    {
        value: "LV",
        label: "Level"
    },
    {
        value: "QI",
        label: "Cimber Air"
    },
    {
        value: "AC",
        label: "Air Canada"
    },
    {
        value: "LU",
        label: "LAN Express"
    },
    {
        value: "SY",
        label: "Sun Country Airlines"
    },
    {
        value: "WX",
        label: "CityJet"
    },
    {
        value: "BD",
        label: "Cambodia Bayon Airlines"
    },
    {
        value: "MS",
        label: "Egyptair"
    },
    {
        value: "I5",
        label: "AirAsia India"
    },
    {
        value: "PD",
        label: "Porter Airlines"
    },
    {
        value: "8N",
        label: "Regional Air Services"
    },
    {
        value: "PS",
        label: "Ukraine International Airlines"
    },
    {
        value: "FV",
        label: "Rossiya-Russian Airlines"
    },
    {
        value: "KR",
        label: "Cambodia Airways"
    },
    {
        value: "W9",
        label: "Wizz Air UK"
    },
    {
        value: "2K",
        label: "Avianca Ecuador"
    },
    {
        value: "3O",
        label: "Air Arabia Maroc"
    },
    {
        value: "LR",
        label: "Avianca Costa Rica"
    },
    {
        value: "JJ",
        label: "LATAM Brasil"
    },
    {
        value: "OPENSKIES",
        label: "Openskies"
    },
    {
        value: "NF",
        label: "Air Vanuatu"
    },
    {
        value: "GS",
        label: "Tianjin Airlines"
    },
    {
        value: "EN",
        label: "Air Dolomiti"
    },
    {
        value: "AJ",
        label: "Aero Contractors"
    },
    {
        value: "BS",
        label: "British International Helicopters"
    },
    {
        value: "CH",
        label: "Bemidji Airlines"
    },
    {
        value: "8E",
        label: "Bering Air"
    },
    {
        value: "4R",
        label: "Renfe"
    },
    {
        value: "FE",
        label: "Far Eastern Air Transport"
    },
    {
        value: "DQ",
        label: "Coastal Air"
    },
    {
        value: "6A",
        label: "Consorcio Aviaxsa"
    },
    {
        value: "SS",
        label: "Corsair International"
    },
    {
        value: "YK",
        label: "Avia Traffic Airline"
    },
    {
        value: "DO",
        label: "Dominicana de Aviaci"
    },
    {
        value: "E3",
        label: "Domodedovo Airlines"
    },
    {
        value: "H7",
        label: "Eagle Air"
    },
    {
        value: "T3",
        label: "Eastern Airways"
    },
    {
        value: "UZ",
        label: "El-Buraq Air Transport"
    },
    {
        value: "B8",
        label: "Eritrean Airlines"
    },
    {
        value: "EA",
        label: "European Air Express"
    },
    {
        value: "GF",
        label: "Gulf Air Bahrain"
    },
    {
        value: "TY",
        label: "Air Caledonie"
    },
    {
        value: "L8",
        label: "Line Blue"
    },
    {
        value: "LEOEXM",
        label: "LEOEXPRESS Minibus"
    },
    {
        value: "5U",
        label: "Transportes Aéreos Guatemaltecos"
    },
    {
        value: "P7",
        label: "Small Planet Airline"
    },
    {
        value: "8I",
        label: "MyAir"
    },
    {
        value: "XK",
        label: "Air Corsica"
    },
    {
        value: "FW",
        label: "Ibex Airlines"
    },
    {
        value: "I7",
        label: "Int'Air Iles"
    },
    {
        value: "LO",
        label: "LOT Polish Airlines"
    },
    {
        value: "B2",
        label: "Belavia Belarusian Airlines"
    },
    {
        value: "2T",
        label: "TruJet"
    },
    {
        value: "TS",
        label: "Air Transat"
    },
    {
        value: "OU",
        label: "Croatia Airlines"
    },
    {
        value: "VX",
        label: "Virgin America"
    },
    {
        value: "D2",
        label: "Severstal Air Company"
    },
    {
        value: "KF",
        label: "Air Belgium"
    },
    {
        value: "JN",
        label: "JOON"
    },
    {
        value: "EH",
        label: "ANA Wings"
    },
    {
        value: "XT",
        label: "Indonesia AirAsia X"
    },
    {
        value: "TA",
        label: "Avianca El Salvador"
    },
    {
        value: "UD",
        label: "Hex'Air"
    },
    {
        value: "RZ",
        label: "Sansa Air"
    },
    {
        value: "4G",
        label: "Gazpromavia"
    },
    {
        value: "G0",
        label: "Ghana International Airlines"
    },
    {
        value: "IC",
        label: "Indian Airlines"
    },
    {
        value: "D6",
        label: "Interair South Africa"
    },
    {
        value: "KV",
        label: "Kavminvodyavia"
    },
    {
        value: "M5",
        label: "Kenmore Air"
    },
    {
        value: "Y9",
        label: "Kish Air"
    },
    {
        value: "7K",
        label: "Kogalymavia Air Company"
    },
    {
        value: "GW",
        label: "Kuban Airlines"
    },
    {
        value: "NG",
        label: "Lauda Air"
    },
    {
        value: "4P",
        label: "Regional sky"
    },
    {
        value: "O2",
        label: "Linear Air"
    },
    {
        value: "LN",
        label: "Libyan Arab Airlines"
    },
    {
        value: "MP",
        label: "Martinair"
    },
    {
        value: "MZ",
        label: "Merpati Nusantara Airlines"
    },
    {
        value: "YV",
        label: "Mesa Airlines"
    },
    {
        value: "MX",
        label: "Mexicana de Aviaci"
    },
    {
        value: "MY",
        label: "Midwest Airlines (Egypt)"
    },
    {
        value: "2M",
        label: "Maya Island Air"
    },
    {
        value: "3R",
        label: "Moskovia Airlines"
    },
    {
        value: "M9",
        label: "Motor Sich"
    },
    {
        value: "NC",
        label: "National Jet Systems"
    },
    {
        value: "RL",
        label: "Royal Falcon"
    },
    {
        value: "BU",
        label: "Baikotovitchestrian Airlines "
    },
    {
        value: "8J",
        label: "Eco Jet"
    },
    {
        value: "LUFTHBUS",
        label: "Lufthansa express bus"
    },
    {
        value: "PI",
        label: "Polar Airlines"
    },
    {
        value: "9B",
        label: "AccesRail"
    },
    {
        value: "I9",
        label: "Air Italy"
    },
    {
        value: "ESTLOREK",
        label: "EST Lorek"
    },
    {
        value: "MQ",
        label: "Envoy Air as American Eagle"
    },
    {
        value: "SLOVAKLNS",
        label: "Slovak Lines "
    },
    {
        value: "PH",
        label: "Polynesian Airlines"
    },
    {
        value: "EY",
        label: "Etihad Airways"
    },
    {
        value: "WY",
        label: "Oman Air"
    },
    {
        value: "SZ",
        label: "Somon Air"
    },
    {
        value: "OK",
        label: "Czech Airlines"
    },
    {
        value: "A9",
        label: "Georgian Airways"
    },
    {
        value: "8P",
        label: "Pacific Coastal Airline"
    },
    {
        value: "AY",
        label: "Finnair"
    },
    {
        value: "U4",
        label: "Buddha Air"
    },
    {
        value: "RQ",
        label: "Kam Air"
    },
    {
        value: "GZ",
        label: "Air Rarotonga"
    },
    {
        value: "YX",
        label: "Republic Airline"
    },
    {
        value: "G7",
        label: "GoJet Airlines"
    },
    {
        value: "ON",
        label: "Nauru Air Corporation"
    },
    {
        value: "QB",
        label: "Qeshm Air"
    },
    {
        value: "V7",
        label: "Volotea"
    },
    {
        value: "RH",
        label: "Republic Express Airlines"
    },
    {
        value: "3P",
        label: "Tiara Air"
    },
    {
        value: "RA",
        label: "Nepal Airlines"
    },
    {
        value: "KE",
        label: "Korean Air"
    },
    {
        value: "CG",
        label: "PNG Air"
    },
    {
        value: "EJ",
        label: "New England Airlines"
    },
    {
        value: "U7",
        label: "Northern Dene Airways"
    },
    {
        value: "J3",
        label: "Northwestern Air"
    },
    {
        value: "O6",
        label: "Avianca Brazil"
    },
    {
        value: "PV",
        label: "PAN Air"
    },
    {
        value: "PU",
        label: "Plus Ultra Lineas Aereas"
    },
    {
        value: "LZ",
        label: "belleair"
    },
    {
        value: "JH",
        label: "Fuji Dream Airlines"
    },
    {
        value: "8F",
        label: "STP Airways"
    },
    {
        value: "QQ",
        label: "Alliance Airlines"
    },
    {
        value: "MN",
        label: "Kulula"
    },
    {
        value: "NU",
        label: "Japan Transocean Air"
    },
    {
        value: "EK",
        label: "Emirates"
    },
    {
        value: "IK",
        label: "Pegas Fly"
    },
    {
        value: "9E",
        label: "Endeavor Air"
    },
    {
        value: "OY",
        label: "Andes Líneas Aéreas"
    },
    {
        value: "Z2",
        label: "Philippines AirAsia"
    },
    {
        value: "BB",
        label: "Seaborne Airlines"
    },
    {
        value: "CX",
        label: "Cathay Pacific"
    },
    {
        value: "CZECHRAILB",
        label: "Czech Rail bus"
    },
    {
        value: "GM",
        label: "Chair Airlines"
    },
    {
        value: "J4",
        label: "Badr Airlines"
    },
    {
        value: "PZ",
        label: "LATAM Paraguay"
    },
    {
        value: "BK",
        label: "Okay Airways"
    },
    {
        value: "5M",
        label: "Sibaviatrans"
    },
    {
        value: "ZS",
        label: "Sama Airlines"
    },
    {
        value: "FT",
        label: "FlyEgypt FT"
    },
    {
        value: "FS",
        label: "Servicios de Transportes A"
    },
    {
        value: "SD",
        label: "Sudan Airways"
    },
    {
        value: "RB",
        label: "Syrian Arab Airlines"
    },
    {
        value: "S5",
        label: "Shuttle America"
    },
    {
        value: "FQ",
        label: "Thomas Cook Airlines"
    },
    {
        value: "GE",
        label: "TransAsia Airways"
    },
    {
        value: "VR",
        label: "TACV"
    },
    {
        value: "HK",
        label: "Yangon Airways"
    },
    {
        value: "EXPRESSBUS",
        label: "ExpressBus"
    },
    {
        value: "XZ",
        label: "Congo Express"
    },
    {
        value: "G4",
        label: "Allegiant Air"
    },
    {
        value: "BY",
        label: "TUI Airways"
    },
    {
        value: "IY",
        label: "Yemenia"
    },
    {
        value: "G6",
        label: "Air Volga"
    },
    {
        value: "Q2",
        label: "Maldivian"
    },
    {
        value: "KW",
        label: "Carnival Air Lines"
    },
    {
        value: "S6",
        label: "Sunrise Airways"
    },
    {
        value: "AX",
        label: "Trans States Airlines"
    },
    {
        value: "3T",
        label: "Turan Air"
    },
    {
        value: "U5",
        label: "USA3000 Airlines"
    },
    {
        value: "UF",
        label: "UM Airlines"
    },
    {
        value: "US",
        label: "US Airways"
    },
    {
        value: "TV",
        label: "Tibet Airlines"
    },
    {
        value: "2W",
        label: "Welcome Air"
    },
    {
        value: "8O",
        label: "West Coast Air"
    },
    {
        value: "IV",
        label: "Wind Jet"
    },
    {
        value: "MF",
        label: "Xiamen Airlines"
    },
    {
        value: "9Y",
        label: "Air Kazakhstan"
    },
    {
        value: "B7",
        label: "Uni Air"
    },
    {
        value: "H8",
        label: "Latin American Wings"
    },
    {
        value: "47",
        label: "88"
    },
    {
        value: "69",
        label: "Royal European Airlines"
    },
    {
        value: "7Y",
        label: "Mann Yadanarpon Airlines"
    },
    {
        value: "4L",
        label: "Euroline"
    },
    {
        value: "ZF",
        label: "Azur Air"
    },
    {
        value: "6P",
        label: "Gryphon Airlines"
    },
    {
        value: "JR",
        label: "Joy Air"
    },
    {
        value: "UR",
        label: "Azur Air Germany"
    },
    {
        value: "TI",
        label: "Tailwind Airlines"
    },
    {
        value: "5F",
        label: "Fly One"
    },
    {
        value: "KT",
        label: "VickJet"
    },
    {
        value: "H5",
        label: "I-Fly"
    },
    {
        value: "G5",
        label: "China Express Airlines"
    },
    {
        value: "D1",
        label: "Domenican Airlines"
    },
    {
        value: "C4",
        label: "LionXpress"
    },
    {
        value: "P8",
        label: "Air Mekong"
    },
    {
        value: "VG",
        label: "VLM Airlines"
    },
    {
        value: "SVENSKABUS",
        label: "Svenska Buss"
    },
    {
        value: "V6",
        label: "VIP Ecuador"
    },
    {
        value: "7Z",
        label: "Halcyonair"
    },
    {
        value: "NB",
        label: "Sterling Airlines"
    },
    {
        value: "1T",
        label: "Hitit Bilgisayar Hizmetleri"
    },
    {
        value: "6Y",
        label: "SmartLynx Airlines"
    },
    {
        value: "2Q",
        label: "Air Cargo Carriers"
    },
    {
        value: "OC",
        label: "Oriental Air Bridge"
    },
    {
        value: "TD",
        label: "Atlantis European Airways"
    },
    {
        value: "NO",
        label: "Neos Air"
    },
    {
        value: "4Q",
        label: "Safi Airlines"
    },
    {
        value: "YM",
        label: "Montenegro Airlines"
    },
    {
        value: "7P",
        label: "AirPanama"
    },
    {
        value: "DT",
        label: "TAAG Angola Airlines"
    },
    {
        value: "A4",
        label: "Azimuth"
    },
    {
        value: "VK",
        label: "LEVEL operated by ANISEC"
    },
    {
        value: "7Q",
        label: "Elite Airways"
    },
    {
        value: "DZ",
        label: "Donghai Airlines"
    },
    {
        value: "YQ",
        label: "TAR Aerolineas"
    },
    {
        value: "6J",
        label: "Solaseed Air"
    },
    {
        value: "E4",
        label: "Elysian Airlines"
    },
    {
        value: "D3",
        label: "Daallo Airlines"
    },
    {
        value: "IA",
        label: "Iraqi Airways"
    },
    {
        value: "XO",
        label: "LTE International Airways"
    },
    {
        value: "CE",
        label: "Chalair"
    },
    {
        value: "7L",
        label: "Sun D'Or"
    },
    {
        value: "JK",
        label: "Spanair"
    },
    {
        value: "S3",
        label: "SBA Airlines"
    },
    {
        value: "UH",
        label: "AtlasGlobal Ukraine"
    },
    {
        value: "VI",
        label: "Volga-Dnepr Airlines"
    },
    {
        value: "GY",
        label: "Colorful Guizhou Airlines"
    },
    {
        value: "5P",
        label: "Small Planet Airlines"
    },
    {
        value: "M4",
        label: "Mistral Air"
    },
    {
        value: "R8",
        label: "AirRussia"
    },
    {
        value: "12",
        label: "12 North"
    },
    {
        value: "QD",
        label: "JC International Airlines"
    },
    {
        value: "QM",
        label: "Air Malawi"
    },
    {
        value: "XV",
        label: "BVI Airways"
    },
    {
        value: "L6",
        label: "Mauritania Airlines International"
    },
    {
        value: "DX",
        label: "DAT Danish Air Transport"
    },
    {
        value: "HC",
        label: "Air Senegal"
    },
    {
        value: "V9",
        label: "Star1 Airlines"
    },
    {
        value: "STANSTDEXP",
        label: "Stansted Express"
    },
    {
        value: "1B",
        label: "Abacus International"
    },
    {
        value: "HT",
        label: "Hellenic Imperial Airways"
    },
    {
        value: "1I",
        label: "NetJets"
    },
    {
        value: "9Q",
        label: "PB Air"
    },
    {
        value: "SB",
        label: "Aircalin"
    },
    {
        value: "YO",
        label: "TransHolding System"
    },
    {
        value: "MB",
        label: "MNG Airlines"
    },
    {
        value: "3F",
        label: "Fly Colombia ( Interliging Flights )"
    },
    {
        value: "ZN",
        label: "Zenith International Airline"
    },
    {
        value: "R5",
        label: "Jordan Aviation"
    },
    {
        value: "NX",
        label: "Air Macau"
    },
    {
        value: "4N",
        label: "Air North"
    },
    {
        value: "QJ",
        label: "Jet Airways"
    },
    {
        value: "EV",
        label: "ExpressJet"
    },
    {
        value: "3G",
        label: "Atlant-Soyuz Airlines"
    },
    {
        value: "2F",
        label: "Frontier Flying Service"
    },
    {
        value: "VO",
        label: "FlyVLM"
    },
    {
        value: "2B",
        label: "AlbaWings"
    },
    {
        value: "3B",
        label: "Binter Cabo Verde"
    },
    {
        value: "FM",
        label: "Shanghai Airlines"
    },
    {
        value: "WJ",
        label: "JetSMART Argentina"
    },
    {
        value: "AU",
        label: "Austral Lineas Aereas"
    },
    {
        value: "H6",
        label: "Bulgarian Air Charter"
    },
    {
        value: "HF",
        label: "Air Cote d'Ivoire"
    },
    {
        value: "ZA",
        label: "Sky Angkor Airlines"
    },
    {
        value: "JF",
        label: "Jetairfly"
    },
    {
        value: "WA",
        label: "KLM Cityhopper"
    },
    {
        value: "GO",
        label: "Kuzu Airlines Cargo"
    },
    {
        value: "L3",
        label: "LTU Austria"
    },
    {
        value: "HE",
        label: "Luftfahrtgesellschaft Walter"
    },
    {
        value: "DM",
        label: "Maersk"
    },
    {
        value: "NW",
        label: "Northwest Airlines"
    },
    {
        value: "O8",
        label: "Siam Air"
    },
    {
        value: "QO",
        label: "Origin Pacific Airways"
    },
    {
        value: "NI",
        label: "Portugalia"
    },
    {
        value: "RD",
        label: "Ryan International Airlines"
    },
    {
        value: "YS",
        label: "Régional"
    },
    {
        value: "AL",
        label: "Skywalk Airlines"
    },
    {
        value: "9S",
        label: "Spring Airlines"
    },
    {
        value: "9T",
        label: "Transwest Air"
    },
    {
        value: "6B",
        label: "TUIfly Nordic"
    },
    {
        value: "ZG",
        label: "Grozny Avia"
    },
    {
        value: "8Z",
        label: "Wizz Air Hungary"
    },
    {
        value: "CV",
        label: "Air Chathams"
    },
    {
        value: "SP",
        label: "SATA Air Acores"
    },
    {
        value: "LF",
        label: "Contour Airlines"
    },
    {
        value: "WQ",
        label: "Swiftair"
    },
    {
        value: "78",
        label: "Southjet cargo"
    },
    {
        value: "YL",
        label: "Yamal Airlines"
    },
    {
        value: "K1",
        label: "Kostromskie avialinii"
    },
    {
        value: "PO",
        label: "Polar Airlines"
    },
    {
        value: "JB",
        label: "Helijet"
    },
    {
        value: "L4",
        label: "LASA Argentina"
    },
    {
        value: "ZX",
        label: "Japan Regio"
    },
    {
        value: "CQ",
        label: "Coastal Aviation"
    },
    {
        value: "BQ",
        label: "Buquebus Líneas Aéreas"
    },
    {
        value: "VIARAILCAD",
        label: "VIA Rail Canada"
    },
    {
        value: "HI",
        label: "Papillon Grand Canyon Helicopters"
    },
    {
        value: "SJRAIL",
        label: "Swedish Railways"
    },
    {
        value: "YR",
        label: "SENIC AIRLINES"
    },
    {
        value: "M7",
        label: "MasAir"
    },
    {
        value: "AN",
        label: "Ansett Australia"
    },
    {
        value: "MR",
        label: "Hunnu Air"
    },
    {
        value: "YY",
        label: "Virginwings"
    },
    {
        value: "4K",
        label: "Askari Aviation"
    },
    {
        value: "QC",
        label: "Camair-co"
    },
    {
        value: "OG",
        label: "AirOnix"
    },
    {
        value: "8U",
        label: "Afriqiyah Airways"
    },
    {
        value: "FG",
        label: "Ariana Afghan Airlines"
    },
    {
        value: "GV",
        label: "Grant Aviation"
    },
    {
        value: "BH",
        label: "Hawkair"
    },
    {
        value: "8H",
        label: "Heli France"
    },
    {
        value: "T4",
        label: "Hellas Jet"
    },
    {
        value: "CL",
        label: "Lufthansa CityLine"
    },
    {
        value: "GH",
        label: "Globus"
    },
    {
        value: "4M",
        label: "LATAM Argentina"
    },
    {
        value: "WU",
        label: "Jetways Airlines Limited"
    },
    {
        value: "FU",
        label: "Fuzhou Airlines"
    },
    {
        value: "HW",
        label: "Hello"
    },
    {
        value: "L5",
        label: "Atlas Atlantique Airlines"
    },
    {
        value: "OM",
        label: "MIAT Mongolian Airlines"
    },
    {
        value: "W5",
        label: "Mahan Air"
    },
    {
        value: "MA",
        label: "Malév"
    },
    {
        value: "N5",
        label: "Skagway Air Service"
    },
    {
        value: "VP",
        label: "VASP"
    },
    {
        value: "4H",
        label: "United Airways"
    },
    {
        value: "SO",
        label: "Salsa d\\\\'Haiti"
    },
    {
        value: "ZC",
        label: "Korongo Airlines"
    },
    {
        value: "WH",
        label: "China Northwest Airlines (WH)"
    },
    {
        value: "5Q",
        label: "BQB Lineas Aereas"
    },
    {
        value: "KG",
        label: "Royal Airways"
    },
    {
        value: "YH",
        label: "Yangon Airways Ltd."
    },
    {
        value: "Q3",
        label: "Anguilla Air Services"
    },
    {
        value: "CB",
        label: "CCML Airlines"
    },
    {
        value: "GB",
        label: "BRAZIL AIR"
    },
    {
        value: "N1",
        label: "N1"
    },
    {
        value: "G1",
        label: "Indya Airline Group"
    },
    {
        value: "M3",
        label: "Air Norway"
    },
    {
        value: "OP",
        label: "PassionAir"
    },
    {
        value: "QU",
        label: "Azur Air Ukraine"
    },
    {
        value: "24",
        label: "Euro Jet"
    },
    {
        value: "EZ",
        label: "Evergreen International Airlines"
    },
    {
        value: "SUNLINES",
        label: "Sun lines"
    },
    {
        value: "ZY",
        label: "Ada Air"
    },
    {
        value: "C1",
        label: "CanXpress"
    },
    {
        value: "EM",
        label: "Empire Airlines"
    },
    {
        value: "C5",
        label: "CommutAir"
    },
    {
        value: "C3",
        label: "Contact Air"
    },
    {
        value: "CS",
        label: "Continental Micronesia"
    },
    {
        value: "0D",
        label: "Darwin Airline"
    },
    {
        value: "DK",
        label: "Eastland Air"
    },
    {
        value: "XE",
        label: "ExpressJet"
    },
    {
        value: "RF",
        label: "Florida West International Airways"
    },
    {
        value: "TU",
        label: "Tunisair"
    },
    {
        value: "T7",
        label: "Twin Jet"
    },
    {
        value: "VA",
        label: "Virgin Australia Airlines"
    },
    {
        value: "W1",
        label: "World Experience Airline"
    },
    {
        value: "ZQ",
        label: "Locair"
    },
    {
        value: "I6",
        label: "Air indus"
    },
    {
        value: "OQ",
        label: "Chongqing Airlines"
    },
    {
        value: "1C",
        label: "OneChina"
    },
    {
        value: "PA",
        label: "Airblue"
    },
    {
        value: "RG",
        label: "Rotana Jet"
    },
    {
        value: "TJ",
        label: "Tradewind Aviation"
    },
    {
        value: "DN",
        label: "Norwegian Air Argentina"
    },
    {
        value: "NS",
        label: "Hebei Airlines"
    },
    {
        value: "VF",
        label: "Valuair"
    },
    {
        value: "HM",
        label: "Air Seychelles"
    },
    {
        value: "KN",
        label: "China United"
    },
    {
        value: "W2",
        label: "Flexflight"
    },
    {
        value: "NA",
        label: "Nesma Air"
    },
    {
        value: "9I",
        label: "Alliance Air"
    },
    {
        value: "TH",
        label: "TransBrasil Airlines"
    },
    {
        value: "Y1",
        label: "Yellowstone Club Private Shuttle"
    },
    {
        value: "F1",
        label: "Fly Brasil"
    },
    {
        value: "1F",
        label: "CB Airways UK ( Interliging Flights )"
    },
    {
        value: "T6",
        label: "Airswift Transport"
    },
    {
        value: "N0",
        label: "Norte Lineas Aereas"
    },
    {
        value: "H9",
        label: "Himalaya Airlines"
    },
    {
        value: "6U",
        label: "Air Cargo Germany"
    },
    {
        value: "XB",
        label: "NEXT Brasil"
    },
    {
        value: "GN",
        label: "GNB Linhas Aereas"
    },
    {
        value: "E1",
        label: "Usa Sky Cargo"
    },
    {
        value: "QY",
        label: "Red Jet Canada"
    },
    {
        value: "4X",
        label: "Red Jet Mexico"
    },
    {
        value: "Y8",
        label: "Marusya Airways"
    },
    {
        value: "FH",
        label: "Freebird Airlines"
    },
    {
        value: "2D",
        label: "Aero VIP (2D)"
    },
    {
        value: "__",
        label: "FakeAirline"
    },
    {
        value: "HQ",
        label: "Thomas Cook Belgium"
    },
    {
        value: "4B",
        label: "BoutiqueAir"
    },
    {
        value: "1X",
        label: "Branson Air Express"
    },
    {
        value: "E9",
        label: "Evelop Airlines"
    },
    {
        value: "DA",
        label: "Aerolinea de Antioquia"
    },
    {
        value: "MEMENTO",
        label: "MementoBUS"
    },
    {
        value: "2O",
        label: "Island Air Kodiak"
    },
    {
        value: "BG",
        label: "Biman Bangladesh Airlines"
    },
    {
        value: "WV",
        label: "Aero VIP"
    },
    {
        value: "TX",
        label: "Air Caraïbes"
    },
    {
        value: "ANDBUS",
        label: "Andbus"
    },
    {
        value: "VC",
        label: "Via Air"
    },
    {
        value: "20",
        label: "Air Salone"
    },
    {
        value: "EJR",
        label: "EJR – East Japan Rail Company"
    },
    {
        value: "IR",
        label: "Iran Air"
    },
    {
        value: "ZZ",
        label: "Zz"
    },
    {
        value: "5E",
        label: "SGA Airlines"
    },
    {
        value: "VIEAPLINES",
        label: "Vienna Airport Lines"
    },
    {
        value: "JM",
        label: "Jambojet"
    },
    {
        value: "REGIOJET",
        label: "RegioJet"
    },
    {
        value: "IZ",
        label: "Arkia"
    },
    {
        value: "HD",
        label: "Air Do"
    },
    {
        value: "CI",
        label: "China Airlines"
    },
    {
        value: "5H",
        label: "Fly540"
    },
    {
        value: "QG",
        label: "Citilink"
    },
    {
        value: "K6",
        label: "Cambodia Angkor Air"
    },
    {
        value: "LB",
        label: "Air Costa"
    },
    {
        value: "Q6",
        label: "Volaris Costa Rica"
    },
    {
        value: "F8",
        label: "Flair Airlines"
    },
    {
        value: "AE",
        label: "Mandarin Airlines"
    },
    {
        value: "DG",
        label: "CebGo"
    },
    {
        value: "OE",
        label: "LaudaMotion"
    },
    {
        value: "XG",
        label: "SunExpress"
    },
    {
        value: "IW",
        label: "Wings Air"
    },
    {
        value: "CZ",
        label: "China Southern Airlines"
    },
    {
        value: "GOOPTI",
        label: "GoOpti"
    },
    {
        value: "LIZ",
        label: "Air Liaison"
    },
    {
        value: "3C",
        label: "Air Chathams Limited 3C"
    },
    {
        value: "CAT",
        label: "CAT- City Airport Train"
    },
    {
        value: "OXFORDBUS",
        label: "Oxford Bus Company"
    },
    {
        value: "LPA",
        label: "Air Leap"
    },
    {
        value: "K8",
        label: "Kan Air"
    },
    {
        value: "O1",
        label: "Orbit Airlines Azerbaijan"
    },
    {
        value: "EE",
        label: "RegionalJet"
    },
    {
        value: "YE",
        label: "Yan Air"
    },
    {
        value: "AA",
        label: "American Airlines"
    },
    {
        value: "9K",
        label: "Cape Air"
    },
    {
        value: "RO",
        label: "Tarom"
    },
    {
        value: "A1",
        label: "Atifly"
    },
    {
        value: "9X",
        label: "Southern Airways Express"
    },
    {
        value: "Z9",
        label: "Bek Air"
    },
    {
        value: "Z8",
        label: "Amaszonas"
    },
    {
        value: "PKSZCZECIN",
        label: "PKS Szczencin"
    },
    {
        value: "TROPOCEAN",
        label: "Tropic Ocean Airways"
    },
    {
        value: "ENZ",
        label: "Jota Aviation"
    },
    {
        value: "TN",
        label: "Air Tahiti Nui"
    },
    {
        value: "REGIOJETB",
        label: "Regiojet Bus"
    },
    {
        value: "76",
        label: "Southjet"
    },
    {
        value: "77",
        label: "Southjet connect"
    },
    {
        value: "AO",
        label: "Avianova (Russia)"
    },
    {
        value: "P6",
        label: "Pascan Aviation"
    },
    {
        value: "CC",
        label: "CM Airlines"
    },
    {
        value: "KM",
        label: "Air Malta"
    },
    {
        value: "GR",
        label: "Aurigny Air Services"
    },
    {
        value: "RK",
        label: "Air Afrique"
    },
    {
        value: "QL",
        label: "Laser Air"
    },
    {
        value: "CJ",
        label: "BA CityFlyer"
    },
    {
        value: "9L",
        label: "Colgan Air"
    },
    {
        value: "OH",
        label: "Comair"
    },
    {
        value: "JE",
        label: "Mango"
    },
    {
        value: "TG",
        label: "Thai Airways International"
    },
    {
        value: "VN",
        label: "Vietnam Airlines"
    },
    {
        value: "OB",
        label: "Boliviana de Aviación"
    },
    {
        value: "OS",
        label: "Austrian Airlines"
    },
    {
        value: "C7",
        label: "Cinnamon Air"
    },
    {
        value: "VB",
        label: "VivaAerobus"
    },
    {
        value: "UX",
        label: "Air Europa"
    },
    {
        value: "FR",
        label: "Ryanair"
    },
    {
        value: "SW",
        label: "Air Namibia"
    },
    {
        value: "WW",
        label: "WOW air"
    },
    {
        value: "SQS",
        label: "Susi Air"
    },
    {
        value: "QX",
        label: "Horizon Air"
    },
    {
        value: "P0",
        label: "Proflight Zambia"
    },
    {
        value: "SA",
        label: "South African Airways"
    },
    {
        value: "YU",
        label: "EuroAtlantic Airways"
    },
    {
        value: "NJ",
        label: "Nordic Global Airlines"
    },
    {
        value: "9M",
        label: "Central Mountain Air"
    },
    {
        value: "NETTBUSS",
        label: "Nettbuss"
    },
    {
        value: "5D",
        label: "Aerolitoral"
    },
    {
        value: "OLLEX",
        label: "Ollex (express)"
    },
    {
        value: "EF",
        label: "EasyFly"
    },
    {
        value: "KI",
        label: "KrasAvia"
    },
    {
        value: "XL",
        label: "LATAM Ecuador"
    },
    {
        value: "V3",
        label: "Carpatair"
    },
    {
        value: "R4",
        label: "Rossiya"
    },
    {
        value: "6T",
        label: "Air Mandalay"
    },
    {
        value: "ES",
        label: "Estelar Latinoamerica"
    },
    {
        value: "7N",
        label: "Pawa Dominicana"
    },
    {
        value: "U6",
        label: "Ural Airlines"
    },
    {
        value: "2C",
        label: "SNCF"
    },
    {
        value: "WK",
        label: "Edelweiss Air"
    },
    {
        value: "ATV",
        label: "Avanti Air"
    },
    {
        value: "2J",
        label: "Air Burkina"
    },
    {
        value: "X5",
        label: "Air Europa express"
    },
    {
        value: "WN",
        label: "Southwest Airlines"
    },
    {
        value: "CITYBUSEXP",
        label: "CityBusExpress"
    },
    {
        value: "DOMO",
        label: "Domo Swiss Express"
    },
    {
        value: "WAGNERTRNS",
        label: "Wagner Transport"
    },
    {
        value: "MAROZZI",
        label: "Marozzi"
    },
    {
        value: "NK",
        label: "Spirit Airlines"
    },
    {
        value: "A3",
        label: "Aegean"
    },
    {
        value: "SM",
        label: "Air Cairo"
    },
    {
        value: "6S",
        label: "SaudiGulf Airlines"
    },
    {
        value: "KY",
        label: "Kunming Airlines"
    },
    {
        value: "P5",
        label: "Wingo airlines"
    },
    {
        value: "RRV",
        label: "Mombasa Air Safari"
    },
    {
        value: "RY",
        label: "Air Jiangxi"
    },
    {
        value: "Z7",
        label: "Amaszonas Uruguay"
    },
    {
        value: "OW",
        label: "Skyward Express Limited"
    },
    {
        value: "GLOBTOUR",
        label: "Globtour"
    },
    {
        value: "GOBUS",
        label: "Gobus"
    },
    {
        value: "GALICJAEX",
        label: "Galicja Express"
    },
    {
        value: "BUS4YOU",
        label: "Bus4You"
    },
    {
        value: "ARRIVA",
        label: "Arriva"
    },
    {
        value: "BUSPLANA",
        label: "Busplana"
    },
    {
        value: "SKANETRAF",
        label: "Skanetrafiken"
    },
    {
        value: "NSB",
        label: "NSB"
    },
    {
        value: "VASTTRAF",
        label: "Vasttrafik"
    },
    {
        value: "H3",
        label: "Harbour Air (Priv)"
    },
    {
        value: "LTKRONOBUS",
        label: "LT Kronoberg"
    },
    {
        value: "SBK",
        label: "Blue Sky Aviation"
    },
    {
        value: "GCS",
        label: "Skyway CR"
    },
    {
        value: "ULENDO",
        label: "Fly Ulendo"
    },
    {
        value: "GOVERNORS",
        label: "Governors Aviation"
    },
    {
        value: "BZ",
        label: "Blue Bird Airways"
    },
    {
        value: "F4",
        label: "Air Flamenco"
    },
    {
        value: "STEWISLAND",
        label: "Stewart Island Flights"
    },
    {
        value: "RLY",
        label: "Air Loyaute"
    },
    {
        value: "DVR",
        label: "Divi Divi Air"
    },
    {
        value: "TGVLYRIA",
        label: "TGV Lyria"
    },
    {
        value: "THELLO",
        label: "Thello"
    },
    {
        value: "GRUMAIR",
        label: "Grumeti Air"
    },
    {
        value: "THALYS",
        label: "Thalys"
    },
    {
        value: "O4",
        label: "Orange2Fly"
    },
    {
        value: "SKYPASADA",
        label: "Sky Pasada"
    },
    {
        value: "NSI",
        label: "NS"
    },
    {
        value: "RZD",
        label: "Russian Railways "
    },
    {
        value: "AMTRAKT",
        label: "Amtrak train "
    },
    {
        value: "BP",
        label: "Air Botswana"
    },
    {
        value: "BI",
        label: "Royal Brunei Airlines"
    },
    {
        value: "BELARURAIL",
        label: "Belarusian Railway"
    },
    {
        value: "GRANDEXP",
        label: "Grand Express"
    },
    {
        value: "COMBOIOS",
        label: "Comboios de Portugal"
    },
    {
        value: "MAV",
        label: "MAV"
    },
    {
        value: "SBB",
        label: "SBB"
    },
    {
        value: "V8",
        label: "ATRAN Cargo Airlines"
    },
    {
        value: "CW",
        label: "Air Marshall Islands"
    },
    {
        value: "AEH",
        label: " Aero4M"
    },
    {
        value: "EILAT",
        label: "Eilat Shuttle"
    },
    {
        value: "ZJ",
        label: "Zambezi Airlines (ZMA)"
    },
    {
        value: "GEORGIBUS",
        label: "Georgian Bus"
    },
    {
        value: "NAVETTE",
        label: "Navette de Vatry"
    },
    {
        value: "INTERGLOB",
        label: "Follow me! Interglobus"
    },
    {
        value: "MICCOLIS",
        label: "Miccolis"
    },
    {
        value: "CILENTO",
        label: "Cilento"
    },
    {
        value: "GP",
        label: "APG Airlines"
    },
    {
        value: "LTKRONOTRN",
        label: "LT Kronoberg"
    },
    {
        value: "S7",
        label: "S7 Airlines"
    },
    {
        value: "MW",
        label: "Mokulele Flight Service"
    },
    {
        value: "IG",
        label: "Air Italy"
    },
    {
        value: "FLYTRISTAR",
        label: "Fly Tristar Services"
    },
    {
        value: "6F",
        label: "Primera Air Nordic"
    },
    {
        value: "ZP",
        label: "Amaszonas del Paraguay S.A. Lineas Aereas"
    },
    {
        value: "EMETEBE",
        label: "Emetebe Airlines"
    },
    {
        value: "ASSALAAM",
        label: "As Salaam Air"
    },
    {
        value: "K5",
        label: "Silverstone Air"
    },
    {
        value: "Y6",
        label: "AB Aviation"
    },
    {
        value: "UNIAIR",
        label: "Unity Air"
    },
    {
        value: "EUROSTAR",
        label: "Eurostar"
    },
    {
        value: "FLYSAFARI",
        label: "Fly Safari Airlink"
    },
    {
        value: "RHONEXP",
        label: "Rhônexpress"
    },
    {
        value: "RV",
        label: "Caspian Airlines"
    },
    {
        value: "C0",
        label: "Centralwings"
    },
    {
        value: "Y5",
        label: "Golden Myanmar Airlines"
    },
    {
        value: "YT",
        label: "Yeti Airways"
    },
    {
        value: "SMSFLUG",
        label: "SMS Flughafen"
    },
    {
        value: "MINIBUD",
        label: "Minibud Ltd."
    },
    {
        value: "FLZ",
        label: "Flightlink"
    },
    {
        value: "7D",
        label: "Madagasikara Airways"
    },
    {
        value: "GRENADINE",
        label: "Grenadine Airways"
    },
    {
        value: "AEROBUSLIS",
        label: "Aerobus Lisbon"
    },
    {
        value: "CFLB",
        label: "CFL"
    },
    {
        value: "MEGABUS",
        label: "Megabus"
    },
    {
        value: "GETBUS",
        label: "Get Bus"
    },
    {
        value: "SITBUS",
        label: "Sit Bus Shuttle"
    },
    {
        value: "9H",
        label: "Air Changan"
    },
    {
        value: "WT",
        label: "Wasaya Airways"
    },
    {
        value: "B3",
        label: "Bhutan Airlines"
    },
    {
        value: "AMTRAKB",
        label: "Amtrak bus"
    },
    {
        value: "100RUMOS",
        label: "100Rumos"
    },
    {
        value: "HOOSIER",
        label: "Hoosier ride"
    },
    {
        value: "DSB",
        label: "DSB"
    },
    {
        value: "GDE",
        label: "Great Dane Airlines"
    },
    {
        value: "QUICKLLAMA",
        label: "QuickLlama"
    },
    {
        value: "QN",
        label: "Skytrans"
    },
    {
        value: "SALTLAKEEX",
        label: "Salt Lake Express"
    },
    {
        value: "ADIRONDACK",
        label: "Adirondack Trailways"
    },
    {
        value: "U3",
        label: "Avies"
    },
    {
        value: "IS",
        label: "Fly Ais Airlines"
    },
    {
        value: "J2",
        label: "Azerbaijan Airlines"
    },
    {
        value: "FI",
        label: "Icelandair"
    },
    {
        value: "FP",
        label: "Pelican Airlines"
    },
    {
        value: "A8",
        label: "Aerolink Uganda"
    },
    {
        value: "CRAVIATION",
        label: "CR Aviation"
    },
    {
        value: "XLL",
        label: "Air Excel Limited"
    },
    {
        value: "IF",
        label: "Fly Baghdad Airlines"
    },
    {
        value: "ARPTSUPERS",
        label: "Airport Supersaver"
    },
    {
        value: "SC",
        label: "Shandong Airlines"
    },
    {
        value: "R3",
        label: "Yakutia Airlines"
    },
    {
        value: "PRESTIA",
        label: "Prestia e Comande"
    },
    {
        value: "BEAUVAIS",
        label: "Beauvaisbus"
    },
    {
        value: "FLYBUS",
        label: "Flybus Iceland"
    },
    {
        value: "AIRBEXP",
        label: "Airport Bus Express"
    },
    {
        value: "CFLT",
        label: "CFL"
    },
    {
        value: "R6",
        label: "RACSA"
    },
    {
        value: "RW",
        label: "Republic Airlines"
    },
    {
        value: "JZ",
        label: "Skyways Express"
    },
    {
        value: "T2",
        label: "Thai Air Cargo"
    },
    {
        value: "C2",
        label: "CanXplorer"
    },
    {
        value: "VIRGINIABR",
        label: "Virginia Breeze"
    },
    {
        value: "NYCAIRPORT",
        label: "NYC Airporter"
    },
    {
        value: "DF",
        label: "Michael Airlines"
    },
    {
        value: "Q5",
        label: "40-Mile Air"
    },
    {
        value: "NQ",
        label: "Air Japan"
    },
    {
        value: "EP",
        label: "Iran Aseman Airlines"
    },
    {
        value: "IE",
        label: "Solomon Airlines"
    },
    {
        value: "SH",
        label: "Sharp Airlines"
    },
    {
        value: "PARKSOFHAM",
        label: "Park's of Hamilton"
    },
    {
        value: "V5",
        label: "Aerovías DAP"
    },
    {
        value: "4A",
        label: "Air Kiribati"
    },
    {
        value: "T5",
        label: "Turkmenistan Airlines"
    },
    {
        value: "XF",
        label: "Vladivostok Air"
    },
    {
        value: "LC",
        label: "Varig Log"
    },
    {
        value: "7W",
        label: "Windrose Airlines"
    },
    {
        value: "KP",
        label: "ASKY Airlines"
    },
    {
        value: "PKP",
        label: "PKP Intercity"
    },
    {
        value: "EG",
        label: "Ernest Airlines"
    },
    {
        value: "MALTATRANS",
        label: "Maltatransfer"
    },
    {
        value: "HP",
        label: "America West Airlines"
    },
    {
        value: "F7",
        label: "Etihad Regional"
    },
    {
        value: "6H",
        label: "Israir"
    },
    {
        value: "TZ",
        label: "Scoot - old"
    },
    {
        value: "WC",
        label: "Avianca Honduras"
    },
    {
        value: "MT",
        label: "Thomas Cook Airlines"
    },
    {
        value: "6I",
        label: "Alsie Express"
    },
    {
        value: "S2",
        label: "Jet Konnect"
    },
    {
        value: "ID",
        label: "Batik Air"
    },
    {
        value: "VZ",
        label: "Thai Vietjet"
    },
    {
        value: "I2",
        label: "Iberia Express"
    },
    {
        value: "HY",
        label: "Uzbekistan Airways"
    },
    {
        value: "BMCAEROBUS",
        label: "BMC Aerobus"
    },
    {
        value: "8V",
        label: "Astral Aviation"
    },
    {
        value: "8T",
        label: "Air Tindi"
    },
    {
        value: "ZW",
        label: "Air Wisconsin"
    },
    {
        value: "GI",
        label: "Itek Air"
    },
    {
        value: "JD",
        label: "Beijing Capital Airlines"
    },
    {
        value: "V2",
        label: "Vision Airlines"
    },
    {
        value: "ZV",
        label: "V Air"
    },
    {
        value: "U9",
        label: "Tatarstan Airlines"
    },
    {
        value: "JC",
        label: "JAL Express"
    },
    {
        value: "UB",
        label: "Myanmar National Airlines"
    },
    {
        value: "DS",
        label: "EasyJet (DS)"
    },
    {
        value: "6O",
        label: "Orbest"
    },
    {
        value: "YN",
        label: "Air Creebec"
    },
    {
        value: "TM",
        label: "LAM Mozambique Airlines"
    },
    {
        value: "7V",
        label: "Federal Airlines"
    },
    {
        value: "VD",
        label: "Air Libert"
    },
    {
        value: "JO",
        label: "JALways"
    },
    {
        value: "E2",
        label: "Eurowings Europe"
    },
    {
        value: "PB",
        label: "Provincial Airlines"
    },
    {
        value: "V4",
        label: "Vieques Air Link"
    },
    {
        value: "P1",
        label: "Regional Sky"
    },
    {
        value: "0V",
        label: "VASCO"
    },
    {
        value: "PX",
        label: "Air Niugini"
    },
    {
        value: "N6",
        label: "Nomad Aviation"
    },
    {
        value: "ZD",
        label: "EWA Air"
    },
    {
        value: "4W",
        label: "Allied Air"
    },
    {
        value: "VH",
        label: "Viva Air"
    },
    {
        value: "OUIGO",
        label: "Ouigo"
    },
    {
        value: "ZSSK",
        label: "Slovak rail"
    },
    {
        value: "ML",
        label: "Air Mediterranee"
    },
    {
        value: "B1",
        label: "Baltic Air lines"
    },
    {
        value: "TO",
        label: "Transavia France"
    },
    {
        value: "P9",
        label: "Peruvian Airlines"
    },
    {
        value: "JA",
        label: "JetSMART"
    },
    {
        value: "TT",
        label: "Tiger Airways Australia"
    },
    {
        value: "6R",
        label: "Alrosa"
    },
    {
        value: "J1",
        label: "One Jet"
    },
    {
        value: "7I",
        label: "Insel Air"
    },
    {
        value: "7J",
        label: "Tajik Air"
    },
    {
        value: "AV",
        label: "Avianca"
    },
    {
        value: "ST",
        label: "Germania"
    },
    {
        value: "DB",
        label: "Brit Air"
    },
    {
        value: "JU",
        label: "Air Serbia"
    },
    {
        value: "F2",
        label: "Safarilink Aviation"
    },
    {
        value: "UL",
        label: "SriLankan Airlines"
    },
    {
        value: "9N",
        label: "Tropic Air Limited"
    },
    {
        value: "PN",
        label: "West Air China"
    },
    {
        value: "HX",
        label: "Hong Kong Airlines"
    },
    {
        value: "J7",
        label: "Afrijet Business Service"
    },
    {
        value: "AW",
        label: "Africa World Airlines"
    },
    {
        value: "EL",
        label: "Ellinair"
    },
    {
        value: "DR",
        label: "Ruili Airlines"
    },
    {
        value: "K9",
        label: "KrasAvia (old iata)"
    },
    {
        value: "OR",
        label: "TUI Airlines Netherlands"
    },
    {
        value: "5C",
        label: "Nature Air"
    },
    {
        value: "8L",
        label: "Lucky air"
    },
    {
        value: "EB",
        label: "Wamos Air"
    },
    {
        value: "WS",
        label: "WestJet"
    },
    {
        value: "YC",
        label: "Yamal Air"
    },
    {
        value: "JX",
        label: "Jambojet"
    },
    {
        value: "5O",
        label: "ASL Airlines France"
    },
    {
        value: "S8",
        label: "SkyWise"
    },
    {
        value: "JEFFERSON",
        label: "Jefferson Lines"
    },
    {
        value: "SG",
        label: "Spicejet"
    },
    {
        value: "XY",
        label: "flynas"
    },
    {
        value: "9C",
        label: "Spring Airlines"
    },
    {
        value: "DV",
        label: "Scat Airlines"
    },
    {
        value: "GT",
        label: "Air Guilin"
    },
    {
        value: "AB",
        label: "Air Berlin"
    },
    {
        value: "9W",
        label: "Jet Airways"
    },
    {
        value: "MJ",
        label: "Myway Airlines"
    },
    {
        value: "VJ",
        label: "VietJet Air"
    },
    {
        value: "YZ",
        label: "Alas Uruguay"
    },
    {
        value: "NL",
        label: "Shaheen Air International"
    },
    {
        value: "FA",
        label: "Fly Safair"
    },
    {
        value: "BM",
        label: "flybmi"
    },
    {
        value: "PF",
        label: "Primera Air"
    },
    {
        value: "JV",
        label: "Perimeter Aviation"
    },
    {
        value: "3Q",
        label: "Yunnan Airlines"
    },
    {
        value: "LJ",
        label: "Jin Air"
    },
    {
        value: "OD",
        label: "Malindo Air"
    },
    {
        value: "KK",
        label: "AtlasGlobal"
    },
    {
        value: "BC",
        label: "Skymark Airlines"
    },
    {
        value: "KQ",
        label: "Kenya Airways"
    },
    {
        value: "KL",
        label: "KLM Royal Dutch Airlines"
    },
    {
        value: "IX",
        label: "Air India Express"
    },
    {
        value: "K7",
        label: "Air KBZ"
    },
    {
        value: "ZI",
        label: "Aigle Azur"
    },
    {
        value: "8A",
        label: "Atlas Blue"
    },
    {
        value: "LX",
        label: "Swiss International Air Lines"
    },
    {
        value: "9V",
        label: "Avior Airlines"
    },
    {
        value: "G9",
        label: "Air Arabia"
    },
    {
        value: "5Z",
        label: "Cem Air"
    },
    {
        value: "Z6",
        label: "Dniproavia"
    },
    {
        value: "XN",
        label: "Xpressair"
    },
    {
        value: "CA",
        label: "Air China"
    },
    {
        value: "N4",
        label: "Nordwind Airlines"
    },
    {
        value: "MO",
        label: "Calm Air"
    },
    {
        value: "X9",
        label: "Fake Airline"
    },
    {
        value: "NZ",
        label: "Air New Zealand"
    },
    {
        value: "VQ",
        label: "Novoair"
    },
    {
        value: "KX",
        label: "Cayman Airways"
    },
    {
        value: "CO",
        label: "Cobalt Air"
    },
    {
        value: "WB",
        label: "Rwandair Express"
    },
    {
        value: "SI",
        label: "Blue Islands"
    },
    {
        value: "8R",
        label: "Sol Líneas Aéreas"
    },
    {
        value: "I4",
        label: "I-Fly"
    },
    {
        value: "YJ",
        label: "Asian Wings Air"
    },
    {
        value: "RX",
        label: "Regent Airways"
    },
    {
        value: "2G",
        label: "Angara airlines"
    },
    {
        value: "7R",
        label: "Rusline"
    },
    {
        value: "JW",
        label: "Vanilla Air"
    },
    {
        value: "5N",
        label: "Smartavia"
    },
    {
        value: "BX",
        label: "Air Busan"
    },
    {
        value: "W4",
        label: "LC Perú"
    },
    {
        value: "G3",
        label: "Gol Transportes Aéreos"
    },
    {
        value: "DP",
        label: "Pobeda"
    },
    {
        value: "PW",
        label: "Precision Air"
    },
    {
        value: "JQ",
        label: "Jetstar Airways"
    },
    {
        value: "5K",
        label: "Hi Fly"
    },
    {
        value: "BJ",
        label: "NouvelAir"
    },
    {
        value: "CM",
        label: "Copa Airlines"
    },
    {
        value: "8M",
        label: "Myanmar Airways"
    },
    {
        value: "B6",
        label: "JetBlue Airways"
    },
    {
        value: "ME",
        label: "Middle East Airlines"
    },
    {
        value: "OZ",
        label: "Asiana Airlines"
    },
    {
        value: "KU",
        label: "Kuwait Airways"
    },
    {
        value: "OJ",
        label: "Fly Jamaica Airways"
    },
    {
        value: "3S",
        label: "Air Antilles Express"
    },
    {
        value: "TL",
        label: "Airnorth"
    },
    {
        value: "QK",
        label: "Air Canada Jazz"
    },
    {
        value: "VW",
        label: "Aeromar"
    },
    {
        value: "QW",
        label: "Qingdao Airlines"
    },
    {
        value: "WP",
        label: "Island Air"
    },
    {
        value: "CF",
        label: "City Airline"
    },
    {
        value: "HO",
        label: "Juneyao Airlines"
    },
    {
        value: "KD",
        label: "Kalstar Aviation"
    },
    {
        value: "QS",
        label: "SmartWings"
    },
    {
        value: "TR",
        label: "Scoot"
    },
    {
        value: "6W",
        label: "Saratov Aviation Division"
    },
    {
        value: "B0",
        label: "Aws express"
    },
    {
        value: "3M",
        label: "Silver Airways"
    },
    {
        value: "IP",
        label: "Island Spirit"
    },
    {
        value: "1D",
        label: "Aerolinea de Antioquia"
    },
    {
        value: "UK",
        label: "Air Vistara"
    },
    {
        value: "AQ",
        label: "9 Air"
    },
    {
        value: "SK",
        label: "SAS"
    },
    {
        value: "DY",
        label: "Norwegian"
    },
    {
        value: "UO",
        label: "Hong Kong Express Airways"
    },
    {
        value: "AF",
        label: "Air France"
    },
    {
        value: "TP",
        label: "TAP Portugal"
    },
    {
        value: "AI",
        label: "Air India Limited"
    },
    {
        value: "VY",
        label: "Vueling"
    },
    {
        value: "JL",
        label: "Japan Airlines"
    },
    {
        value: "KC",
        label: "Air Astana"
    },
    {
        value: "SN",
        label: "Brussels Airlines"
    },
    {
        value: "FC",
        label: "Fly Corporate"
    },
    {
        value: "TF",
        label: "Braathens Regional Aviation"
    },
    {
        value: "TB",
        label: "tuifly.be"
    },
    {
        value: "EW",
        label: "Eurowings"
    },
    {
        value: "IB",
        label: "Iberia Airlines"
    },
    {
        value: "Q7",
        label: "Sky Bahamas"
    },
    {
        value: "7M",
        label: "MAP Linhas Aéreas"
    },
    {
        value: "FO",
        label: "Flybondi"
    },
    {
        value: "CP",
        label: "Compass Airlines"
    },
    {
        value: "9R",
        label: "SATENA"
    },
    {
        value: "FD",
        label: "Thai AirAsia"
    },
    {
        value: "FZ",
        label: "Fly Dubai"
    },
    {
        value: "X3",
        label: "TUIfly"
    },
    {
        value: "TK",
        label: "Turkish Airlines"
    },
    {
        value: "7H",
        label: "Ravn Alaska"
    },
    {
        value: "D8",
        label: "Norwegian International"
    },
    {
        value: "JP",
        label: "Adria Airways"
    },
    {
        value: "BURLINGTON",
        label: "Burlington Trailways"
    },
    {
        value: "2Z",
        label: "Passaredo"
    },
    {
        value: "CN",
        label: "Canadian National Airways"
    },
    {
        value: "WM",
        label: "Windward Islands Airways"
    },
    {
        value: "PC",
        label: "Pegasus"
    },
    {
        value: "LK",
        label: "Lao Skyway"
    },
    {
        value: "RP",
        label: "Chautauqua Airlines"
    },
    {
        value: "LY",
        label: "El Al Israel Airlines"
    },
    {
        value: "F9",
        label: "Frontier Airlines"
    },
    {
        value: "3L",
        label: "Intersky"
    },
    {
        value: "J9",
        label: "Jazeera Airways"
    },
    {
        value: "UE",
        label: "Nasair"
    },
    {
        value: "DH",
        label: "Dennis Sky"
    },
    {
        value: "TW",
        label: "Tway Airlines"
    },
    {
        value: "3H",
        label: "AirInuit"
    },
    {
        value: "W6",
        label: "Wizzair"
    },
    {
        value: "Y4",
        label: "Volaris"
    },
    {
        value: "6E",
        label: "IndiGo Airlines"
    },
    {
        value: "PG",
        label: "Bangkok Airways"
    },
    {
        value: "VTTRANSLIN",
        label: "Vermont Translines"
    },
    {
        value: "DD",
        label: "Nok Air"
    },
    {
        value: "FY",
        label: "Firefly"
    },
    {
        value: "ED",
        label: "Airblue"
    },
    {
        value: "FN",
        label: "Fastjet"
    },
    {
        value: "JT",
        label: "Lion Air"
    },
    {
        value: "YB",
        label: "BoraJet"
    },
    {
        value: "7C",
        label: "Jeju Air"
    },
    {
        value: "SL",
        label: "Thai Lion Air"
    },
    {
        value: "CZECHRAIL",
        label: "Czech Rail"
    },
    {
        value: "C9",
        label: "SkyWise"
    },
    {
        value: "AZ",
        label: "Alitalia"
    },
    {
        value: "AR",
        label: "Aerolineas Argentinas"
    },
    {
        value: "4O",
        label: "Interjet"
    },
    {
        value: "AM",
        label: "AeroMéxico"
    },
    {
        value: "H2",
        label: "Sky Airline"
    },
    {
        value: "5J",
        label: "Cebu Pacific"
    },
    {
        value: "BV",
        label: "Blue Panorama"
    },
    {
        value: "DE",
        label: "Condor"
    },
    {
        value: "QZ",
        label: "Indonesia AirAsia"
    },
    {
        value: "U2",
        label: "easyJet"
    },
    {
        value: "ALSA",
        label: "Alsa"
    },
    {
        value: "4U",
        label: "germanwings"
    },
    {
        value: "3K",
        label: "Jetstar Asia Airways"
    },
    {
        value: "DL",
        label: "Delta Air Lines"
    },
    {
        value: "UT",
        label: "UTair"
    },
    {
        value: "HA",
        label: "Hawaiian Airlines"
    },
    {
        value: "WG",
        label: "Sunwing"
    },
    {
        value: "R2",
        label: "Orenburg Airlines"
    },
    {
        value: "UN",
        label: "Transaero Airlines"
    },
    {
        value: "AK",
        label: "AirAsia"
    },
    {
        value: "SJ",
        label: "Sriwijaya Air"
    },
    {
        value: "SQ",
        label: "Singapore Airlines"
    },
    {
        value: "UP",
        label: "Bahamasair"
    },
    {
        value: "BA",
        label: "British Airways"
    },
    {
        value: "SU",
        label: "Aeroflot Russian Airlines"
    },
    {
        value: "EQ",
        label: "TAME"
    },
    {
        value: "BW",
        label: "Caribbean Airlines"
    },
    {
        value: "GA",
        label: "Garuda Indonesia"
    },
    {
        value: "ET",
        label: "Ethiopian Airlines"
    },
    {
        value: "HV",
        label: "Transavia"
    },
    {
        value: "G8",
        label: "Go Air"
    },
    {
        value: "UJ",
        label: "AlMasria Universal Airlines"
    },
    {
        value: "BF",
        label: "French Bee"
    },
    {
        value: "Q9",
        label: "Wataniya Airways"
    },
    {
        value: "EU",
        label: "Chengdu Airlines"
    },
    {
        value: "MENORCA",
        label: "Berlinas Menorca"
    },
    {
        value: "DUBLINBUS",
        label: "Dublin Bus"
    },
    {
        value: "LAMEZIA",
        label: "Lamezia Multiservizi"
    },
    {
        value: "VYRAIL",
        label: "Vy"
    },
    {
        value: "EMT",
        label: "Emetebe Airlines"
    },
    {
        value: "FKK",
        label: "Unity Air"
    },
    {
        value: "KA",
        label: "Cathay Dragon"
    },
    {
        value: "XR",
        label: "Corendon Airlines Europe"
    },
    {
        value: "DC",
        label: "Braathens Regional Airways"
    },
    {
        value: "I3",
        label: "ATA Airlines (Iran)"
    },
    {
        value: "ZO",
        label: "Zagros Airlines"
    },
    {
        value: "B5",
        label: "East African"
    },
    {
        value: "EIREAGLE",
        label: "Eireagle"
    },
    {
        value: "GTOURSSA",
        label: "Giosy tours SA"
    },
    {
        value: "WESTFALEN",
        label: "Westfalen Bahn"
    },
    {
        value: "NORDWEST",
        label: "NordWestBahn"
    },
    {
        value: "KORAIL",
        label: "Korail"
    },
    {
        value: "YCAT",
        label: "Yuma County Area Transit"
    },
    {
        value: "DALATRAFIK",
        label: "Dalatrafik"
    },
    {
        value: "FLYGBUSSAR",
        label: "Flygbussarna"
    },
    {
        value: "BT",
        label: "airBaltic"
    },
    {
        value: "HZ",
        label: "Aurora Airlines"
    },
    {
        value: "MM",
        label: "Peach Aviation"
    },
    {
        value: "HH",
        label: "Taban Airlines"
    },
    {
        value: "EUROTRANS",
        label: "AD EuroTrans"
    },
    {
        value: "ARRIVAUK",
        label: "Arriva United Kingdom"
    },
    {
        value: "MTRNORDIC",
        label: "MTR Nordic"
    },
    {
        value: "OL",
        label: "Samoa Airways"
    },
    {
        value: "IZY",
        label: "IZY"
    },
    {
        value: "5W",
        label: "WESTBahn"
    },
    {
        value: "TAGKOMPANI",
        label: "Tagkompaniet"
    },
    {
        value: "GL",
        label: "Air Greenland"
    },
    {
        value: "BARONSBUS",
        label: "Barons Bus"
    },
    {
        value: "NYTRAILWAY",
        label: "New York Trailways"
    },
    {
        value: "OUIBUS",
        label: "Ouibus"
    },
    {
        value: "LM",
        label: "LoganAir LM"
    },
    {
        value: "SHA",
        label: "Shree Airlines"
    },
    {
        value: "FULLINGTON",
        label: "Fullington Trailways"
    },
    {
        value: "CAPITALCOL",
        label: "Capital - Colonial Trailways"
    },
    {
        value: "BL",
        label: "Jetstar Pacific"
    },
    {
        value: "YORKSTIGER",
        label: "Yorkshire Tiger"
    },
    {
        value: "FIRSTBUS",
        label: "First Bus"
    },
    {
        value: "HIGHPEAK",
        label: "High Peak"
    },
    {
        value: "YELLOWBUS",
        label: "Yellow Buses"
    },
    {
        value: "BATHBUS",
        label: "Bath Bus Company"
    },
    {
        value: "BLEKINGEB",
        label: "Blekingetrafiken bus"
    },
    {
        value: "IJ",
        label: "Spring Airlines Japan"
    },
    {
        value: "VRRAIL",
        label: "VR"
    },
    {
        value: "BE",
        label: "flybe"
    },
    {
        value: "OBB",
        label: "OBB"
    },
    {
        value: "SLORAIL",
        label: "Slovenian Railways"
    },
    {
        value: "ULT",
        label: "Public Traffic Uppland train"
    },
    {
        value: "MEGABUST",
        label: "Megabus train"
    },
    {
        value: "MEGABUSB",
        label: "Megabus bus"
    },
    {
        value: "CITYZAP",
        label: "Cityzap"
    },
    {
        value: "COASTLINER",
        label: "Coastliner"
    },
    {
        value: "GREENLINE",
        label: "Green Line"
    },
    {
        value: "STAGECOACB",
        label: "Stagecoach bus"
    },
    {
        value: "OXFORDTUBE",
        label: "Oxford Tube"
    },
    {
        value: "ENNO",
        label: "Enno"
    },
    {
        value: "METRONOM",
        label: "Metronom"
    },
    {
        value: "SUDTHURING",
        label: "Sud-Thuringen-Bahn"
    },
    {
        value: "VIAS",
        label: "Vias"
    },
    {
        value: "MERIBOBBRB",
        label: "Meridian, BOB, BRB"
    },
    {
        value: "EUROBAHN",
        label: "Eurobahn"
    },
    {
        value: "LANDERBAHN",
        label: "Landerbahn"
    },
    {
        value: "ABELLIO",
        label: "Abellio"
    },
    {
        value: "CZECHRAILT",
        label: "Czech Rail train"
    },
    {
        value: "SLT",
        label: "Stockholm Public Transport train"
    },
    {
        value: "S0",
        label: "Aerolineas Sosa"
    },
    {
        value: "AG",
        label: "Aruba Airlines"
    },
    {
        value: "GX",
        label: "GX airlines"
    },
    {
        value: "PR",
        label: "Philippine Airlines"
    },
    {
        value: "UQ",
        label: "Urumqi Airlines"
    },
    {
        value: "UG",
        label: "TunisAir Express"
    },
    {
        value: "K2",
        label: "EuroLot"
    },
    {
        value: "VE",
        label: "VE"
    },
    {
        value: "D9",
        label: "Aeroflot-Don"
    },
    {
        value: "IT",
        label: "Tigerair Taiwan"
    },
    {
        value: "ZK",
        label: "Great Lakes Airlines"
    },
    {
        value: "HR",
        label: "Hahn Airlines"
    },
    {
        value: "HU",
        label: "Hainan Airlines"
    },
    {
        value: "PY",
        label: "Surinam Airways"
    },
    {
        value: "7B",
        label: "Fly Blue Crane"
    },
    {
        value: "LW",
        label: "Latin American Wings"
    },
    {
        value: "RI",
        label: "Mandala Airlines"
    },
    {
        value: "Q8",
        label: "Trans Air Congo"
    },
    {
        value: "XC",
        label: "Corendon"
    },
    {
        value: "WE",
        label: "Thai Smile"
    },
    {
        value: "PL",
        label: "Southern Air Charter"
    },
    {
        value: "AT",
        label: "Royal Air Maroc"
    },
    {
        value: "BR",
        label: "EVA Air"
    },
    {
        value: "MH",
        label: "Malaysia Airlines"
    },
    {
        value: "OX",
        label: "Orient Thai Airlines"
    },
    {
        value: "S4",
        label: "SATA Azores Airlines"
    },
    {
        value: "7T",
        label: "Trenitalia"
    },
    {
        value: "ARDATUR",
        label: "Arda Tur"
    },
    {
        value: "MAGICSHUT",
        label: "Magical Shuttle"
    },
    {
        value: "CRNJAT",
        label: "Crnja tours"
    },
    {
        value: "VS",
        label: "Virgin Atlantic Airways"
    },
    {
        value: "GDNEX",
        label: "GDN Express"
    },
    {
        value: "GLOBALBIO",
        label: "Global biomet "
    },
    {
        value: "CHRISTRAN",
        label: "Christian Transfers"
    },
    {
        value: "YELLOWTRAN",
        label: "Yellow Transfers"
    },
    {
        value: "GQ",
        label: "Sky Express"
    },
    {
        value: "2A",
        label: "Deutsche Bahn"
    },
    {
        value: "AUTNA",
        label: "Autna SL - Spain"
    },
    {
        value: "CHINARAIL",
        label: "China Railway"
    },
    {
        value: "OKBUS",
        label: "OK bus"
    },
    {
        value: "MOUNTAINLI",
        label: "Mountain Line Transit Authority"
    },
    {
        value: "BOLTBUS",
        label: "BoltBus"
    },
    {
        value: "SWISSTOURS",
        label: "SwissTours"
    },
    {
        value: "AUTOLIFEDE",
        label: "Autolinee federico"
    },
    {
        value: "NH",
        label: "All Nippon Airways"
    },
    {
        value: "AEROBUSBCN",
        label: "Aerobus Barcelona"
    },
    {
        value: "ROMAEX",
        label: "Roma Express"
    },
    {
        value: "BALEARIA",
        label: "Balearia"
    },
    {
        value: "5G",
        label: "MAYAir"
    },
    {
        value: "WD",
        label: "Amsterdam Airlines"
    },
    {
        value: "Z4",
        label: "Ibom Air"
    },
    {
        value: "ZB",
        label: "Air Albania"
    },
    {
        value: "P4",
        label: "Air Peace Limited"
    },
    {
        value: "ITALONTV",
        label: "Italo NTV"
    },
    {
        value: "ITALOBUS",
        label: "Italobus"
    },
    {
        value: "W7",
        label: "Wings of Lebanon"
    },
    {
        value: "RS",
        label: "Air Seoul"
    }
]