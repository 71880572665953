import { ChipOwnProps } from "@mui/material"
import currency from "currency.js"
import moment from "moment"

export const formatCPF = (value: string) => {
    return value.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const formatCreditCard = (value: string) => {
    return value.replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})\d+?$/, '$1')
}

export const formatCVV = (value: string) => {
    return value.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1')
}

export const formatExpiry = (value: string) => {
    return value.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})\d+?$/, '$1')
}

export const formatCNPJ = (value: string) => {
    return value.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,4})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
    
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export const formatCEP = (value: string) => {
    return value.replace(/\D/g,'').replace(/(\d{5})(\d)/,'$1-$2')
}

export const getBidStatusColor = (status: string) => {
    let color: ChipOwnProps['color'];

    switch (status) {
        case 'PENDING':
            color = 'default'
            break
        case 'ACCEPTED':
            color = 'success'
            break
        case 'DENIED':
            color = 'error'
            break
        case 'AWAITING_ORDER':
            color = 'warning'
            break
        case 'DONE':
            color = 'info'
            break
        case 'ON_ORDER':
            color = 'warning'
            break
    }

    return color
}

export const getBidStatusLabel = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'Pendente'
        case 'ACCEPTED':
            return 'Aceita'
        case 'DENIED':
            return 'Recusada'
        case 'AWAITING_ORDER':
            return 'Aguardando Emissão'
        case 'ON_ORDER':
            return 'Em Emissão'
        case 'DONE':
            return 'Finalizada'
    }
}

export const getOrderConfirmationColor = (row: {confirmation: string, created_at: Date}): ChipOwnProps['color'] => {
    if (row.confirmation === 'PENDING' && moment(row.created_at).isBefore(moment().subtract(48, 'hours'))) {
        return 'error'
    }

    switch (row.confirmation) {
        case 'PENDING':
            return 'info'
        case 'CANCELLED':
            return 'error'
        case 'CONFIRMED':
            return'success'
    }
}

export const getSupportTicketStatusLabel = (status: string) => {
    switch (status) {
        case 'OPEN':
            return 'Aberto'
        case 'CLOSED':
            return 'Fechado'
    }
}

export const getSupportTicketStatusColor = (status: string): ChipOwnProps['color'] => {
    switch (status) {
        case 'OPEN':
            return 'default'
        case 'CLOSED':
            return 'success'
    }
}

export const getOrderConfirmationLabel = (status: string) => {
    switch (status) {
        case 'PENDING':
            return 'Pendente'
        case 'CANCELLED':
            return 'Cancelada'
        case 'CONFIRMED':
            return 'Confirmada'
    }
}

export const getPaymentStatusColor = (status: string): ChipOwnProps['color'] => {
    switch (status) {
        case 'AWAITING_ORDER_CONFIRMATION':
            return 'default'
        case 'CANCELLED':
            return 'error'
        case 'PENDING':
            return 'default'
        case 'PAID':
            return 'success'
        case 'LATE':
            return 'warning'
    }
}

export const getPaymentStatusLabel = (status: string) => {
    switch (status) {
        case 'AWAITING_ORDER_CONFIRMATION':
            return 'Aguardando Confirmação'
        case 'CANCELLED':
            return 'Cancelado'
        case 'PENDING':
            return 'Pendente'
        case 'PAID':
            return 'Pago'
        case 'LATE':
            return 'Atrasado'
    }
}

export const formatMoney = (value: number) => {
    return currency(value).format({
        symbol: 'R$ ',
        decimal: ',',
        separator: '.'
    })    
}

export const getPixTypeLabel = (value: string) => {
    switch (value) {
        case 'CPF':
            return 'CPF'
        case 'EMAIL':
            return 'E-mail'
        case 'PHONE_NUMBER':
            return 'Telefone'
        case 'CNPJ':
            return 'CNPJ'
        case 'RANDOM_KEY':
            return 'Chave Aleatória'
    }
}

export const formatDate = (value: Date) => {
    return moment(value).format('DD/MM/YYYY')
}

export const formatDateTime = (value: Date) => {
    return moment(value).format('DD/MM/YYYY HH:mm:ss')
}

export const capitalizeFirstLetter = (label: string) => {
    return label.charAt(0).toUpperCase() + label.slice(1)
}

export const nl2br = (text: string) => text.replace(/\n/g, "<br />")

export const getSVGString = (svgNode: HTMLElement): string => {
    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink')
    
    const getCSSStyles = (parentElement: HTMLElement) => {
      let selectorTextArr = []
  
      selectorTextArr.push('#' + parentElement.id)
    }
  
    let cssStyleText = getCSSStyles(svgNode)
  
    let serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgNode)
  
    return svgString
  }
  

export const randomHexColorCode = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
};