import { Alert, Autocomplete, Button, CurrencyInput, DatePicker, Grid, Input, Layout, Loader, NumericInput, Typography } from "../../../components";
import { Paper } from "../../../components";
import { useFormik } from "formik";
import { UpdateOrderValidationSchema } from "@next-pontos/validations";
import {useEffect, useMemo, Fragment, useContext} from 'react'
import { Chip, Link, Tooltip } from "@mui/material";
import { formatCPF, formatDate, formatMoney, getBidStatusColor, getBidStatusLabel, getOrderConfirmationColor, getOrderConfirmationLabel, getPaymentStatusColor, getPaymentStatusLabel } from "../../../utils";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AIRLINES_OPTIONS, CARD_BRAND_OPTIONS, COMPANY_OPTIONS } from "../../../constants";
import currency from "currency.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAxios } from "../../../hooks";
import AuthContext from "../../../context/auth";

export function Order () { 
    const {me} = useContext(AuthContext)
    const params = useParams()
    
    const [order] = useAxios(`/order/${params.id}`)
    const [orderUpdate, updateOrder] = useAxios({
        method: 'PUT',
        url: `/order/${params.id}`
    }, {
        manual: true
    })

    const bid = order?.data?.bid
    
    const [cards] = useAxios(`/card`)

    const [password, getPassword] = useAxios(`/account/${bid?.account_id}/password`, {
        manual: true
    })
    
    const formik = useFormik({
        initialValues: {
            flight_date: '',
            airline: '',
            reservation_code: '',
            amount: '',
            fees: 0,
            additional_cost: 0,
            card_id: '',
            sell_price: 0,
            pax: '',
            author_name: '',
            pax_name: '',
            origin_airport_code: ''
        },
        initialStatus: 'draft',
        onSubmit: async (values) => {
            await updateOrder({
                data: values
            })

            window.location.reload()
        },
        validateOnMount: true,
        validationSchema: UpdateOrderValidationSchema
    })

    useEffect(() => {
        if (order.data) {
            formik.resetForm({
                values: {
                    flight_date: order.data.flight_date,
                    airline: order.data.airline,
                    reservation_code: order.data.reservation_code,
                    amount: order.data.miles,
                    fees: Number(order.data.fees),
                    additional_cost: Number(order.data.additional_cost),
                    card_id: order.data.card_id,
                    sell_price: Number(order.data.sell_price),
                    pax: order.data.pax,
                    author_name: order.data.author_name,
                    pax_name: order.data.pax_name,
                    origin_airport_code: order.data.origin_airport_code
                },
                touched: {},
                status: 'initiated'
            })
        }
    }, [order.data])

    const CARDS_OPTIONS = useMemo(() => {
        return (cards?.data || []).map((card: { id: number, last_four_digits: string, brand: string, alias: string}) => ({
            value: card.id,
            label: `**** ${card.last_four_digits} (${card.alias})`
        }))
    }, [cards])

    const totalCost = useMemo(() => {
        const amount_k = currency(formik.values.amount || 0, {precision: 3}).divide(1000).value
        const price_per_k = bid?.price_per_k || 0
        const fees = formik.values.fees || 0
        const additional_cost = formik.values.additional_cost || 0
        
        const miles_cost = currency(amount_k, {precision: 3}).multiply(price_per_k)
        const final_cost = miles_cost.add(fees).add(additional_cost)

        return final_cost.value
    }, [formik, bid])

    const airlineOptions = useMemo(() => {
        return AIRLINES_OPTIONS.map(item => ({
            value: item.label,
            label: item.label
        })).sort((a, b) => a.label.localeCompare(b.label))
    }, [])

    const paxOptions = useMemo(() => {
        const maxPax = bid?.pax || 9
        
        const maxPaxOptions = (new Array(maxPax).fill(0)).map((_, index) => {
            return {
                label: (index + 1).toString(),
                value: (index + 1).toString()
            }
        })

        if (!bid?.pax) {
            return maxPaxOptions
        } else {
            const usedPax = bid?.orders
            ?.filter((item: { id: number}) => item.id !== Number(params.id))
            ?.filter((item: {confirmation: string}) => item.confirmation === 'PENDING' || item.confirmation === 'CONFIRMED')?.map((item: {pax: number}) => item.pax)?.reduce((prev: number, curr: number) => prev + curr, 0)
            
            return maxPaxOptions.filter((_, index) => index < (maxPaxOptions.length - usedPax))
        }

    }, [bid?.pax, bid?.orders])

    const milesAvailable = useMemo(() => {
        const usedMiles = bid?.orders
        ?.filter((item: { id: number}) => item.id !== Number(params.id))
        ?.filter((item: {confirmation: string}) => item.confirmation === 'PENDING' || item.confirmation === 'CONFIRMED')?.map((item: {miles: number}) => item.miles)?.reduce((prev: number, curr: number) => prev + curr, 0)
    
        return currency(bid?.amount).subtract(usedMiles).value
    }, [bid])

    const { amount, additional_cost, fees, sell_price } = formik.values
    const { setErrors }  = formik

    useEffect(() => {
        if (Number(amount) > Number(milesAvailable)) {
            setErrors({ amount: 'A quantidade de milhas deve ser menor que o disponível na oferta' })
        }
    }, [amount, milesAvailable, setErrors])
    
    const disabledForm = useMemo(() => {
        // REMOVER
        return false
        return order?.data?.bid?.status === 'DONE' || order?.data?.payment?.status === 'PAID'
    }, [order.data])

    const soldCostPerK = useMemo(() => {
        const totalMilesCost = currency(sell_price).subtract(fees).subtract(additional_cost).value
        return currency(totalMilesCost).divide(currency(amount, {precision: 3}).divide(1000)).value
    }, [additional_cost, amount, fees, sell_price])

    return (
        <Layout>
            <Grid container justifyContent="center" gap={4} minHeight={"calc(100vh - 64px)"} alignItems="flex-start" paddingTop={5}>
                {order.loading || orderUpdate.loading || cards.loading || formik.status === 'draft' ? (
                    <div style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                ) : (<Fragment>
                <Grid item xs={12} sm={4}>
                    <Alert color="warning" icon={false}>
                        <strong>Atenção!</strong>
                        <br />
                        Alterar os dados da emissão pode interferir no valor do pagamento ao fornecedor.
                    </Alert>
                    {disabledForm && (<Alert color="error" icon={false} sx={{
                        marginTop: 2
                    }}>
                        <strong>Atenção!</strong>
                        <br />
                        Não é possível alterar emissões de ofertas finalizadas e emissões que já foram pagas. Se você deseja alterar confirmar ou cancelar altere pela <Link href="/emissoes">lista de emissões.</Link>
                    </Alert>)}
                    <Paper elevation={1} sx={{
                        marginTop: 4
                    }}>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center">
                        <Grid item xs={12} marginBottom={2}>
                            <Typography textAlign={"center"} variant="h5">Editar Emissão</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DatePicker 
                                label="Data do Voo"
                                value={formik.values.flight_date ? moment(formik.values.flight_date) : null}
                                onChange={(value) => {
                                    formik.setFieldValue('flight_date', value?.toDate(), true)
                                }}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}> 
                            <Autocomplete 
                                label="Companhia"
                                onChange={({value}) => {
                                    formik.setFieldValue('airline', value, true)
                                }}
                                options={airlineOptions}
                                onBlur={formik.handleBlur}
                                value={airlineOptions.find(item => item.value === formik.values.airline)}
                                helperText={formik.touched.airline && formik.errors.airline}
                                error={!!formik.touched.airline && !!formik.errors.airline}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Emissor"
                                name="author_name"
                                onChange={formik.handleChange}
                                value={formik.values.author_name}
                                helperText={formik.touched.author_name && formik.errors.author_name}
                                error={!!formik.touched.author_name && !!formik.errors.author_name}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Localizador"
                                name="reservation_code"
                                onChange={event => {
                                    formik.setFieldValue('reservation_code' ,event.target.value.toUpperCase().trim())
                                }}
                                value={formik.values.reservation_code}
                                helperText={formik.touched.reservation_code && formik.errors.reservation_code}
                                error={!!formik.touched.reservation_code && !!formik.errors.reservation_code}
                                onBlur={formik.handleBlur}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input 
                                label="Passageiros"
                                name="pax"
                                select
                                options={paxOptions}
                                onChange={formik.handleChange}
                                value={formik.values.pax}
                                helperText={formik.touched.pax && formik.errors.pax}
                                error={!!formik.touched.pax && !!formik.errors.pax}
                                onBlur={formik.handleBlur}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumericInput
                                label="Milhas"
                                name="amount"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.amount}
                                helperText={formik.touched.amount && formik.errors.amount}
                                error={!!formik.touched.amount && !!formik.errors.amount}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CurrencyInput
                                label="Taxas (R$)"
                                name="fees"
                                onChangeValue={(_, originalValue) => {
                                    formik.setFieldValue('fees', originalValue, true)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.fees}
                                helperText={formik.touched.fees && formik.errors.fees}
                                error={!!formik.touched.fees && !!formik.errors.fees}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Bagagem despachada, marcação de assentos, etc..." >
                                <div>
                                <CurrencyInput
                                    label="Custos Adicionais (R$)"
                                    name="additional_cost"
                                    onChangeValue={(_, originalValue) => {
                                        formik.setFieldValue('additional_cost', originalValue, true)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.additional_cost}
                                    helperText={formik.touched.additional_cost && formik.errors.additional_cost}
                                    error={!!formik.touched.additional_cost && !!formik.errors.additional_cost}
                                    disabled={disabledForm}
                                />
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CurrencyInput
                                label="Valor de Venda (R$)"
                                name="sell_price"
                                onChangeValue={(_, originalValue) => {
                                    formik.setFieldValue('sell_price', originalValue, true)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.sell_price}
                                helperText={formik.touched.sell_price && formik.errors.sell_price}
                                error={!!formik.touched.sell_price && !!formik.errors.sell_price}
                                disabled={disabledForm}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Cartão utilizado para pagamento das taxas." placement="top">
                                <div>
                                <Autocomplete 
                                    label="Cartão"
                                    onChange={({value}) => {
                                        formik.setFieldValue('card_id', value, true)
                                    }}
                                    options={CARDS_OPTIONS}
                                    onBlur={formik.handleBlur}
                                    value={CARDS_OPTIONS.find((item: { value: string }) => item.value === formik.values.card_id) || null}
                                    helperText={formik.touched.card_id && formik.errors.card_id}
                                    error={!!formik.touched.card_id && !!formik.errors.card_id}
                                    disabled={disabledForm}
                                />
                            </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Tooltip title="Caso haja mais de um passageiro, insira o nome de apenas um deles." placement="top">
                                <div>
                            <Input
                                label="Nome do Passageiro"
                                name="pax_name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pax_name}
                                helperText={formik.touched.pax_name && formik.errors.pax_name}
                                error={!!formik.touched.pax_name && !!formik.errors.pax_name}
                            />
                            </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                label="Aeroporto de Origem"
                                name="origin_airport_code"
                                onChange={e => {
                                    formik.setFieldValue('origin_airport_code', e.target.value.replace(/([^A-Z])/ig, '').toLocaleUpperCase().slice(0, 3), true)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.origin_airport_code}
                                helperText={formik.touched.origin_airport_code && formik.errors.origin_airport_code}
                                error={!!formik.touched.origin_airport_code && !!formik.errors.origin_airport_code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Status:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right">
                                    <Chip color={getOrderConfirmationColor({
                                        confirmation: order?.data?.confirmation,
                                        created_at: order?.data?.created_at
                                    })} label={getOrderConfirmationLabel(order?.data?.confirmation)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Custo Total:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="end">
                                        {formatMoney(totalCost)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Lucro Total:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="end">
                                        {formatMoney(
                                            currency(formik.values.sell_price)
                                            .subtract(totalCost).value
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {me?.role === 'ADMIN' && (<Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontWeight="bold">
                                        Milheiro Vendido:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography textAlign="end">
                                        {
                                            formatMoney(soldCostPerK)
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>)}
                        <Grid item marginTop={2}>
                            <Button disabled={!formik.isValid || disabledForm} type="submit">Editar</Button>
                        </Grid>
                    </Grid>
                    </form>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={1} >
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Detalhes da Oferta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            ID:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                    <Link href={`/ofertas/${bid?.id}`}>
                                        # {bid?.id}
                                    </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Data:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography textAlign="end">
                                            {moment(bid?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} textAlign="end">
                                        <Chip color={getBidStatusColor(bid.status)} label={getBidStatusLabel(bid.status)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Fornecedor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.user?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Companhia:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography textAlign="end">
                                            {COMPANY_OPTIONS.find(item => item.value === bid?.company)?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={3}>
                                        <Typography fontWeight="bold">
                                            Quantidade:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Typography textAlign="end">
                                            {Number(milesAvailable).toLocaleString('pt-BR')}
                                        </Typography>
                                        {milesAvailable < Number (bid?.amount) && (<Typography textAlign="end" marginLeft={1} color="red" fontWeight="bold">
                                            (-{Number(currency(bid?.amount).subtract(milesAvailable).value).toLocaleString('pt-BR')})
                                        </Typography>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            CPFs Disponíveis:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} display="flex" flexDirection="row" justifyContent="flex-end">
                                        <Typography textAlign="end">
                                            {bid?.pax === null ? '∞' : Number(paxOptions[paxOptions.length - 1]?.value || 0)}
                                        </Typography>
                                        {bid?.pax !== null && Number(paxOptions[paxOptions.length - 1]?.value || 0) < bid?.pax && (
                                            <Typography textAlign="end" marginLeft={1} color="red" fontWeight="bold">
                                                (-{currency(bid?.pax).subtract(Number(paxOptions[paxOptions.length - 1]?.value || 0))?.value})
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            Valor do Milheiro:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography textAlign="end">
                                            {formatMoney(bid?.price_per_k)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography fontWeight="bold">
                                            Emissões da Oferta:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} textAlign="end">
                                        {
                                            bid?.orders ? bid?.orders?.map((item: {id: string}) => {
                                                return (
                                                    <Fragment>
                                                    &nbsp;
                                                    <Link href={`/emissoes/${item.id}`}>
                                                        # {item.id}
                                                    </Link>
                                                    </Fragment>
                                                )
                                            }) : (
                                                <Typography>
                                                    -
                                                </Typography>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados da Conta</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Login:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.account?.login}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Senha:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                            <Typography textAlign="end">
                                        {
                                            password?.data ? (
                                                password?.data?.password
                                            ) : (
                                            <div style={{cursor: 'pointer'}} onClick={() => getPassword()}>
                                                <VisibilityIcon fontSize="inherit" />
                                            </div>
                                            )
                                        }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Contrassenha:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                            <Typography textAlign="end">
                                        {
                                            password?.data ? (
                                                password?.data?.secondary_password ?? '-'
                                            ) : (
                                            <div style={{cursor: 'pointer'}} onClick={() => getPassword()}>
                                                <VisibilityIcon fontSize="inherit" />
                                            </div>
                                            )
                                        }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Nome do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.account?.holder_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            E-mail do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {bid?.account?.holder_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            CPF do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatCPF(bid?.account?.holder_cpf || '')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Data de Nasc. do Titular:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatDate(bid?.account?.holder_birthday || '')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} style={{ marginTop: 30 }}>
                        <Grid container padding={2} spacing={2}>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography textAlign={"center"} variant="h5">Dados do Pagamento</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            ID:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Link href={`/pagamentos/${order?.data?.payment?.id}`}>
                                            # {order?.data?.payment?.id}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Vencimento:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatDate(order?.data?.payment?.due_date)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Status:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} textAlign="end">
                                        <Chip color={getPaymentStatusColor(order?.data?.payment?.status)} label={getPaymentStatusLabel(order?.data?.payment?.status)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography fontWeight="bold">
                                            Valor:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography textAlign="end">
                                            {formatMoney(order?.data?.payment?.amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                </Fragment>)}
            </Grid>
        </Layout>
    )
}