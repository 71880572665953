import { Box, FormHelperText, Link, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, Grid, Input, NumericInput, Paper, Typography } from '../../components';
import { useAxios } from '../../hooks';
import { Fragment, LegacyRef, useMemo, useRef } from 'react';
import { COMPANY_OPTIONS } from '../../constants';
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from 'formik';
import { CreateLeadBidValidationSchema } from '@next-pontos/validations';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './index.css'
import { APP_CONTACTS, APP_NAME } from '@next-pontos/settings';

export const LandingPage = () => {
    const theme = useTheme()
    const [getParam] = useSearchParams()
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const isLG = useMediaQuery(theme.breakpoints.up('lg'));
    const isXL = useMediaQuery(theme.breakpoints.up('xl'));

    const containerWidth = useMemo(() => {
        if (isXL) {
            return '70%'
        }

        if (isLG) {
            return '80%'
        }

        if (isMD) {
            return '85%'
        }

        return '90%'
    }, [isMD, isLG, isXL])

    const captchaRef = useRef<ReCAPTCHA>(null)

    const [companies] = useAxios('/api/public/company')
    const [, createLeadBid] = useAxios({
        url: '/api/public/lead/bid',
        method: 'POST'
    }, {
        manual: true
    })
    
    const companyOptions = useMemo(() => {
        return COMPANY_OPTIONS.filter(company => companies?.data?.find((c: { company: string }) => c.company === company.value))
    }, [companies.data])
    
    const formik = useFormik({
        initialValues: {
            email: '',
            company: '',
            amount: '',
            origin: getParam.get('origin') ?? '',
            reCaptchaToken: ''
        },
        onSubmit: async (values, helpers) => {
            const { status } = await createLeadBid({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Cotação enviada com sucesso! Você vai receber em alguns instantes nossa oferta no seu email.', {variant: 'success'})
                helpers.resetForm()
            } else {
                enqueueSnackbar('Erro interno.', {variant: 'error'})
            }

            captchaRef.current?.reset()
        },
        validationSchema: CreateLeadBidValidationSchema.concat(Yup.object({
            amount: Yup.number().when('company', ([company], schema) => {
                const min_amount = (companies.data || []).find((program: { company: string }) => program.company === company)?.min_amount || 0
                
                return schema.min(min_amount, `O mínimo para esta companhia é ${Number(min_amount).toLocaleString('pt-BR')} milhas`).required('Este campo é obrigatório').test({
                    test: value => {
                        if (Number(value) <= 0) {
                            return false
                        }
            
                        return true
                    },
                    message: 'Deve ser maior que zero'
                })
            })
        }))
    })

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column'
        }}>
            <AppBar component="nav" sx={{
                backgroundColor: 'white',
                height: 64
            }}>
                <Toolbar
                    style={{
                        paddingLeft: 140,
                        paddingRight: 140
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flex: 1
                    }}>
                        <img src='/logo.png' alt="Logo" style={{ width: 110 }} />
                    </div> 
                    {isSM && (<div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end'
                    }}>
                        <Button href='/'>
                            Entre ou cadastre-se
                        </Button>
                    </div>)}
                </Toolbar>
            </AppBar>
            <div
                style={{
                    display: 'flex',
                    minHeight: !isSM ? undefined : '70vh',
                    width: '100%',
                    background: theme.palette.primary.main,
                    marginTop: 64,
                    paddingTop: 64,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 64
                }}
            >
                <Grid container width={containerWidth} spacing={2}> 
                    <Grid item xs={12} sm={5}>
                        <Grid container spacing={4} height='100%'>
                            <Grid item xs={12}>
                                <Typography variant='h2' fontWeight='bold' color="white" textAlign={!isSM ? "center" : undefined}>
                                Transforme suas milhas aéreas e pontos do cartão de crédito em dinheiro!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' color="white">
                                Na {APP_NAME}, transformamos suas milhas aéreas paradas em grana na sua conta. Tem milhas sobrando? A gente compra! 
                                <br />
                                É simples, rápido, seguro e sem complicação. Converta suas milhas em dinheiro fácil e aproveite essa oportunidade com a gente!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={0} sm={2} />
                    <Grid item xs={12} sm={5}>
                        <Paper elevation={1} sx={{
                            borderRadius: 5
                        }}>
                            <form onSubmit={formik.handleSubmit}>
                            <Grid container gap={2} padding={3}>
                                <Grid item xs={12}>
                                    <Grid container gap={1} mb={3}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' fontWeight='bold' color={theme.palette.primary.main}>
                                                Converta Suas Milhas em Dinheiro!
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='subtitle2' color={theme.palette.primary.main}>
                                                Diga quantas milhas você tem, qual o programa e seu email. Vamos fazer uma oferta imperdível!
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        label="Email"
                                        name="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        helperText={formik.touched.email && formik.errors.email}
                                        error={!!formik.touched.email && !!formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        label="Programa"
                                        options={companyOptions}
                                        name="company"
                                        select
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.company}
                                        helperText={formik.touched.email && formik.errors.email}
                                        error={!!formik.touched.email && !!formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumericInput 
                                        label="Quantidade"
                                        name="amount"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.amount}
                                        helperText={formik.touched.amount && formik.errors.amount}
                                        error={!!formik.touched.amount && !!formik.errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" flexDirection={"column"}>
                                    <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY as string}
                                        onChange={(data) => {
                                            formik.setFieldValue('reCaptchaToken', data, true)
                                        }}
                                    />
                                    {!!formik.touched.amount && !!formik.errors.amount && (<FormHelperText error>{formik.errors.reCaptchaToken}</FormHelperText>)}
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Button type='submit'>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{
                    display: 'flex',
                    minHeight: !isSM ? undefined : '70vh',
                    width: '100%',
                    background: 'white',
                    paddingTop: 64,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 64
                }}
            >
                <Grid container width={containerWidth} spacing={2}> 
                    <Grid item xs={12} sm={5}>
                        <img src="/money-wallet.jpg" alt="money" style={{
                            width: '100%',
                            borderRadius: '10px 50px 10px 50px'
                        }}/>
                    </Grid>
                    <Grid item xs={0} sm={2} />
                    <Grid item xs={12} sm={5}>
                    <Grid container spacing={4} height='100%'>
                        <Grid item xs={12}>
                                <Typography variant='h2' fontWeight='bold' color={'black'}>
                                    Receba em até 2 dias úteis!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' color={theme.palette.primary.main}>
                                    Aqui na {APP_NAME}, a gente sabe que você não quer esperar para receber seu dinheiro. Por isso, garantimos o pagamento em até 2 dias úteis. Venda suas milhas e veja o dinheiro cair rapidinho na sua conta. Rápido, fácil e sem complicação!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{
                    display: 'flex',
                    minHeight: !isSM ? undefined : '70vh',
                    width: '100%',
                    background: theme.palette.primary.main,
                    paddingTop: 64,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 64
                }}
            >
                <Grid container width={containerWidth} spacing={2}> 
                    <Grid item xs={12}>
                        <Typography variant='h2' fontWeight='bold' color="white" textAlign="center">
                            O caminho até o dinheiro na sua conta!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Stepper 
                        orientation={isSM ? 'horizontal' : 'vertical'}
                        activeStep={-1} 
                        alternativeLabel={isSM}
                        sx={{
                            color: 'black'
                        }}>
                        <Step>
                            <StepLabel sx={{color: 'white'}}>Oferte suas milhas disponíveis</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Nosso time analisa sua oferta em até 24 horas úteis</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Você informa os detalhes das suas milhas</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Nosso time emite passagens com suas milhas</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Seu pagamento em 2 dias úteis após a utilização</StepLabel>
                        </Step>
                    </Stepper>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    background: 'white',
                    paddingTop: 64,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 64
                }}
            >
                <Grid container width={containerWidth} spacing={2} textAlign="center" display="flex" justifyContent="center" alignItems="center" gap={!isSM ? 5 : undefined}> 
                    <Grid item xs={12} sm={6} textAlign="center">
                        <img src="/logo.png" style={{ width: 200 }} alt='Logo' />
                        {process.env.APP_CIENT === 'NEXT' && (
                            <Fragment><Typography mt={2}>PAX VIAGENS LTDA</Typography>
                        <Typography>45.176.365/0001-00</Typography></Fragment>)}
                    </Grid>
                    <Grid item xs={12} sm={6} textAlign="center" alignItems="center" display={"flex"} flexDirection="column">
                        <Link href={`https://wa.me/${APP_CONTACTS.whatsapp}`}>
                            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                                <WhatsAppIcon fontSize='small' />
                                <Typography ml={1}>+55 (11) 91856-9055</Typography>
                            </div>
                        </Link>
                        <Typography>Atendimento <strong>exclusivo</strong> via WhatsApp.</Typography>
                        <Typography>Segunda a Sábado de 09h às 19h.</Typography>
                    </Grid>
                </Grid>
            </div>
            {process.env.APP_CLIENT === 'NEXT' && (<div
                style={{
                    display: 'flex',
                    width: '100%',
                    background: theme.palette.primary.main,
                    paddingTop: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 10
                }}
            >
                <Grid container width={containerWidth} textAlign="left" display="flex" justifyContent="center" alignItems="center" gap={1}> 
                    <Grid item xs={12} textAlign="center">
                        <Typography fontWeight="bold" fontSize={14} color="white">Quem é a {APP_NAME}?</Typography>
                    </Grid>
                    <Grid item xs={12} color="white" textAlign={"center"}>
                        <Typography fontSize={14}>Next é uma empresa fundada por <strong>Erik Galdino</strong>, que atua no mercado de compra e venda de milhas desde 2016, que também atua como mentor de agências de viagem e negócios ligados a milhas aéreas desde 2018. <Link href="https://www.instagram.com/erikgaldino" color="rgb(255, 255, 255)">@erikgaldino</Link></Typography>
                    </Grid>
                </Grid>
            </div>)}
        </Box>
    )
}